import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';

import { T4DiagramProps } from 'features/entity4/visualizations/_shared/_components/graphRendererBase';
import { AccountMapRendererBase } from './accountMapRendererBase';

export class AccountMapAccountViewRenderer extends AccountMapRendererBase {
	/** @internal */
	constructor(props: T4DiagramProps) {
		super(props);
		this.initDiagram = this.initDiagram.bind(this);
	}

	/**
	 * Diagram initialization method, which is passed to the ReactDiagram component.
	 * This method is responsible for making the diagram and initializing the model, any templates,
	 * and maybe doing other initialization tasks like customizing tools.
	 * The model's data should not be set here, as the ReactDiagram component handles that.
	 */
	private initDiagram(): go.Diagram {
		/*
		 * note: layereddigraphlayout is more technically correct for multiple parents, but
		 * different trees show up under an umbrella of other trees like in the below discussion
		 * this discussion just says: "try the tree layout and see if it works..."
		 * https://forum.nwoods.com/t/node-layout-in-banded-layereddigraphlayout/15904/11
		 */
		const diagram = new go.Diagram({
			initialAutoScale: go.AutoScale.Uniform,
			layout: new go.TreeLayout({
				angle: 90,
				arrangementSpacing: new go.Size(100, 100),
				arrangement: go.TreeArrangement.Horizontal,
			}),
			scrollMargin: 1024,

			model: this.createDiagramModel(),
			nodeTemplate: this.createNodeTemplate(
				true,
				'TreeExpanderButton',
				this.props.isFieldVisible,
			),
			linkTemplate: this.createLinkTemplate(true),
			groupTemplate: this.createOrphanGroupTemplate(
				'Standalone Accounts',
				3,
				this.props.isOrphanGroupVisible,
			),
		});

		diagram.undoManager.isEnabled = true;
		diagram.toolManager.mouseWheelBehavior = go.WheelMode.Zoom;

		this.createLegendGroupTemplate(diagram);
		this.setDiagramEvents(diagram);

		return diagram;
	}

	public render() {
		return (
			<ReactDiagram
				ref={this.diagramRef}
				divClassName="accountview-gojs-diagram"
				style={this.diagramStyle}
				initDiagram={this.initDiagram}
				nodeDataArray={this.props.nodeDataArray}
				linkDataArray={this.props.linkDataArray}
				modelData={this.props.modelData}
				onModelChange={this.props.onModelChange}
			/>
		);
	}
}
