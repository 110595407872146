import { observer } from 'mobx-react-lite';
import {
	SigmaFolder,
	SigmaWorkbook,
} from 'modules/clients/customer-api/src/sigmaEmbed';
import { useSnackbar } from 'notistack';
import {
	FC,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useClients } from 'shared/hooks/useClients';

//#region Types

export type SigmaMode = 'view' | 'create' | 'edit';

//#endregion

//#region Context

export type SigmaContextProps = {
	initializing: boolean;
	sigmaUrl?: string;
	recentlyDeletedFolder?: SigmaFolder;
	recentlyDeletedWorkbooks: SigmaWorkbook[];
	workbooks: SigmaWorkbook[];
	workbook?: SigmaWorkbook;
	folders: SigmaFolder[];
	folder?: SigmaFolder;
	generateSigmaUrl: (
		sigmaWorkbook?: SigmaWorkbook | undefined,
		mode?: SigmaMode,
	) => void;
	addFolder: (folder: SigmaFolder) => void;
	updateFolder: (folder: SigmaFolder) => void;
	removeFolder: (folder: SigmaFolder) => void;
	selectFolder: (folder: SigmaFolder) => void;
	deleteWorkbook: (deletedWorkbook: SigmaWorkbook) => void;
	addWorkbook: (newWorkbook: SigmaWorkbook) => void;
	updateWorkbook: (updatedWorkbook: SigmaWorkbook) => void;
	updateWorkbooks: (updatedWorkbooks: SigmaWorkbook[]) => void;
	removeWorkbooks: (deletedWorkbooks: SigmaWorkbook[]) => void;
	selectWorkbook: (
		selectedWorkbook: SigmaWorkbook | undefined,
		mode?: SigmaMode,
	) => void;
};

const SigmaContext = createContext<SigmaContextProps>({
	initializing: true,
	recentlyDeletedWorkbooks: [],
	workbooks: [],
	folders: [],
	addFolder: () => {},
	updateFolder: () => {},
	removeFolder: () => {},
	selectFolder: () => {},
	addWorkbook: () => {},
	deleteWorkbook: () => {},
	updateWorkbook: () => {},
	updateWorkbooks: () => {},
	removeWorkbooks: () => {},
	selectWorkbook: () => {},
	generateSigmaUrl: () => {},
});

//#endregion

//#region Provider

export type SigmaProviderProps = {
	solution?: string;
	children: ReactNode;
};

export const SigmaProvider: FC<SigmaProviderProps> = observer(
	({ children, solution }) => {
		const { customerApiClient } = useClients();
		const { enqueueSnackbar } = useSnackbar();

		const [initializing, setInitalizing] = useState(true);
		const [sigmaUrl, setSigmaUrl] = useState<string>();
		const [recentlyDeletedFolder, setRecentlyDeletedFolder] =
			useState<SigmaFolder>();
		const [workbooks, setWorkbooks] = useState<SigmaWorkbook[]>([]);
		const [workbook, setWorkbook] = useState<SigmaWorkbook>();
		const [folders, setFolders] = useState<SigmaFolder[]>([]);
		const [folder, setFolder] = useState<SigmaFolder>();
		const [userId, setUserId] = useState<string>();
		const [loadingData, setLoadingData] = useState(false);
		const recentlyDeletedWorkbooks = useMemo(() => {
			if (recentlyDeletedFolder) {
				return workbooks.filter((x) => x.folderId === recentlyDeletedFolder.id);
			} else {
				return [];
			}
		}, [recentlyDeletedFolder, workbooks]);

		const generateSigmaUrl = useCallback(
			async (
				sigmaWorkbook: SigmaWorkbook | undefined = workbook,
				mode: SigmaMode = 'view',
			) => {
				const response = await customerApiClient.sigmaEmbed.sigmaUrl({
					workbookId: sigmaWorkbook?.id!,
					workbookUrlId: sigmaWorkbook?.urlId!,
					embedMode: mode,
				});
				setSigmaUrl(response.data);
			},
			[customerApiClient.sigmaEmbed, workbook],
		);

		const updateWorkbooks = useCallback(
			(updatedWorkbooks: SigmaWorkbook[]) => {
				const nextWorkbooks = workbooks.filter(
					(currentWorkbook) =>
						!updatedWorkbooks.some((x) => x.id === currentWorkbook.id),
				);
				nextWorkbooks.push(...updatedWorkbooks);
				setWorkbooks(nextWorkbooks);
			},
			[workbooks],
		);

		const selectWorkbook = useCallback(
			async (
				selectedWorkbook: SigmaWorkbook | undefined,
				mode: SigmaMode = 'view',
			) => {
				if (selectedWorkbook?.folderId !== folder?.id) {
					setFolder(folders.find((x) => x.id === selectedWorkbook?.folderId));
				}
				if (workbook !== selectedWorkbook) {
					await generateSigmaUrl(selectedWorkbook, mode);
					setWorkbook(selectedWorkbook);
				}
			},
			[folder?.id, folders, generateSigmaUrl, workbook],
		);
		const selectFolder = useCallback(
			(folder: SigmaFolder) => {
				if (folder) {
					setFolder(folder);
					const matchingWorkbooks = workbooks.filter(
						(x) => x.folderId === folder.id,
					);
					if (matchingWorkbooks.length > 0) {
						selectWorkbook(matchingWorkbooks[0]);
					} else {
						setWorkbook(undefined);
					}
				}
			},
			[selectWorkbook, workbooks],
		);
		const addWorkbook = useCallback(
			(newWorkbook: SigmaWorkbook) => {
				const currentWorkbooks = [...workbooks];
				currentWorkbooks.push(newWorkbook);
				setWorkbooks(currentWorkbooks);
				selectWorkbook(newWorkbook);
			},
			[selectWorkbook, workbooks],
		);

		const updateWorkbook = useCallback(
			(updatedWorkbook: SigmaWorkbook) => {
				const nextWorkbooks = workbooks.filter(
					(x) => x.id !== updatedWorkbook.id,
				);
				nextWorkbooks.push(updatedWorkbook);
				setWorkbooks(
					nextWorkbooks.sort((a, b) => a.name.localeCompare(b.name)),
				);
				selectWorkbook(updatedWorkbook);
			},
			[selectWorkbook, workbooks],
		);

		const deleteWorkbook = useCallback(
			(deletedWorkbook: SigmaWorkbook) => {
				updateWorkbook(deletedWorkbook);
				setWorkbook(undefined);
				selectWorkbook(workbooks.filter((x) => x.folderId === folder?.id)[0]);
			},
			[folder?.id, selectWorkbook, updateWorkbook, workbooks],
		);

		const removeWorkbooks = useCallback(
			(deletedWorkbooks: SigmaWorkbook[]) => {
				const nextWorkbooks = workbooks.filter(
					(currentWorkbook) =>
						!deletedWorkbooks.some((x) => x.id === currentWorkbook.id),
				);
				setWorkbooks(nextWorkbooks);
				selectWorkbook(workbooks.filter((x) => x.folderId === folder?.id)[0]);
			},
			[folder?.id, selectWorkbook, workbooks],
		);

		const addFolder = useCallback(
			(folder: SigmaFolder) => {
				const currentFolders = [...folders];
				currentFolders.push(folder);
				setFolders(currentFolders);
				selectFolder(folder);
			},
			[folders, selectFolder],
		);

		const removeFolder = useCallback(
			(folder: SigmaFolder) => {
				const nextFolders = folders.filter((x) => x.id !== folder.id);
				setFolders(nextFolders);

				if (nextFolders.length > 0) {
					const nextFolder = nextFolders[0];
					selectFolder(nextFolder);
				} else {
					setFolder(undefined);
				}
			},
			[folders, selectFolder],
		);

		const updateFolder = useCallback(
			(folder: SigmaFolder) => {
				const nextFolders = folders.filter((x) => x.id !== folder.id);
				nextFolders.push(folder);
				setFolders(nextFolders);
				selectFolder(folder);
			},
			[folders, selectFolder],
		);

		const initialize = useCallback(async () => {
			try {
				if (!loadingData && !userId) {
					setLoadingData(true);
					const query = customerApiClient.sigmaEmbed
						.loadAllThingsSigma()
						.then((response) => {
							if (response.data) {
								if (response.data) {
									const { memberId, sigmaWorkbooks, solutionFolders } =
										response.data;
									setUserId(memberId);
									setWorkbooks(
										sigmaWorkbooks.sort((a, b) => a.name.localeCompare(b.name)),
									);
									const recentlyDeletedFolderSearch = solutionFolders.filter(
										(x) => x.name === 'Recently Deleted',
									);
									setFolders(
										solutionFolders.filter(
											(x) => x.id !== recentlyDeletedFolderSearch[0].id,
										),
									);
									setRecentlyDeletedFolder(recentlyDeletedFolderSearch[0]);
									setLoadingData(false);
									setInitalizing(false);
								}
							}
						});
					await Promise.all([query]);
				}
			} catch (error) {
				enqueueSnackbar({
					message: 'Failed to initialize Sigma.',
					variant: 'error',
				});
			} finally {
				setInitalizing(false);
			}
		}, [customerApiClient.sigmaEmbed, enqueueSnackbar, selectFolder]);

		useEffect(() => {
			if (initializing) {
				initialize();
			}
		}, [initializing, initialize]);

		useEffect(() => {
			if (folders.length > 0 && !folder) {
				selectFolder(folders.filter((x) => x.name === solution)[0]);
			}
		}, [folders, selectFolder, folder]);

		return (
			<SigmaContext.Provider
				value={{
					initializing: initializing,
					sigmaUrl: sigmaUrl,
					recentlyDeletedFolder: recentlyDeletedFolder,
					recentlyDeletedWorkbooks: recentlyDeletedWorkbooks,
					workbooks: workbooks,
					workbook: workbook,
					folders: folders,
					folder: folder,
					generateSigmaUrl: generateSigmaUrl,
					addFolder: addFolder,
					updateFolder: updateFolder,
					removeFolder: removeFolder,
					selectFolder: selectFolder,
					addWorkbook: addWorkbook,
					deleteWorkbook: deleteWorkbook,
					updateWorkbook: updateWorkbook,
					updateWorkbooks: updateWorkbooks,
					removeWorkbooks: removeWorkbooks,
					selectWorkbook: selectWorkbook,
				}}
			>
				{children}
			</SigmaContext.Provider>
		);
	},
);

//#endregion

//#region Hook

export type UseSigma = SigmaContextProps;

export function useSigma(): UseSigma {
	return useContext(SigmaContext);
}

//#endregion
