import {
	BackupTable,
	Balance,
	Home,
	Input,
	ScreenshotMonitor,
	SettingsInputComponent,
	TrendingUp,
} from '@mui/icons-material';
import { SigmaPage } from 'features/_shared/_pages/sigmaPage';
import { useCash4Configurations } from 'features/_shared/_providers/cash4ConfigurationsProvider';
import { SigmaDashboardProvider } from 'features/entity4/dashbaord/_providers/dashboardProvider';
import { DashboardPageV2 } from 'features/entity4/dashbaord/pages/dashboardPageV2';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import {
	Redirect,
	Route,
	Switch,
	generatePath,
	useLocation,
} from 'react-router-dom';
import { Layout } from 'shared/components/layout';
import { Navbar } from 'shared/components/navbar';
import { NavigationItemProps } from 'shared/components/navigation/navigationItem';
import { NavigationMenu } from 'shared/components/navigation/navigationMenu';
import SolutionPicker from 'shared/components/solutionPicker/solutionPicker';
import { paths, validIdRegex } from 'shared/constants/paths';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useUser } from 'shared/hooks/useUser';
import { T4Solution } from 'shared/t4Solutions';
import { CannotDisplay } from '../../shared/components/cannotDisplay';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from '../../shared/constants/cannotDisplayMessaging';
import { AccountIntegrationsPage } from './accountIntegrations/accountIntegrationsPage';
import { AccountIntegrationsProvider } from './accountIntegrations/providers/accountIntegrationsProviders';
import AnalyticsStudioPage from './analyticsStudio/analyticsStudioPage';
import { BalancesPage } from './balances/balancesPage';
import { CategoriesRoute } from './categories/categoriesPage';
import { DataImportsPage } from './dataImports/dataImportsPage';
import HoldingsRoute from './investments/holdings/holdingsPage';
import InvestmentTransactionsRoute from './investments/transactions/transactionsPage';
import { PortalsRoute } from './portals/portalsRoute';
import { ReconciliationsProvider } from './reconciliations/_providers/reconciliationsProvider';
import { ReconciliationsPage } from './reconciliations/reconciliationsPage';
import { DataProvider } from './rules/createRuleModal/providers/DataProvider';
import { RulesRoute } from './rules/rulesPage';
import { TransactionsProvider } from './transactions/providers/useTransactions';
import { TransactionsPage } from './transactions/transactionsPage';

export const Cash4Layout: FC = observer(() => {
	const location = useLocation();
	const {
		cash4: { isAuthor, isViewer, isDataImporter, isIntegrationsAdmin },
	} = useUser();
	const { data: configurations } = useCash4Configurations();
	const {
		sigmaStudioEnabled,
		investmentTransactionsEnabled,
		projectedTransactionsEnabled,
		sigmaDashboardEnabled,
		hidePowerBi,
	} = useT4FeatureFlags();

	const [selectedItem, setSelectedItem] = useState('');
	const [navigationItems, setNavigationItems] = useState<NavigationItemProps[]>(
		[],
	);

	useEffect(() => {
		const path = location.pathname;
		if (path.includes(paths.cash4.transactions.href)) {
			setSelectedItem('transactions');
		} else if (path.includes(paths.cash4.portals.href)) {
			const last = path.split('/').pop();
			setSelectedItem(last !== 'portals' ? last : 'portals');
		} else if (path.includes(paths.cash4.rules.href)) {
			setSelectedItem('rules');
		} else if (path.includes(paths.cash4.investments.holdings.href)) {
			setSelectedItem('holdings');
		} else if (path.includes(paths.cash4.investments.transactions.href)) {
			setSelectedItem('investment-transactions');
		} else if (path.includes(paths.cash4.categories.href)) {
			setSelectedItem('categories');
		} else if (path.includes(paths.cash4.analyticsStudioBeta.href)) {
			setSelectedItem('sigmaStudio');
		} else if (path.includes(paths.cash4.analytics.href)) {
			setSelectedItem('analyticsStudio');
		} else if (path.includes(paths.cash4.accountIntegrations.href)) {
			setSelectedItem('accountIntegrations');
		} else if (path.includes(paths.cash4.dataImports.href)) {
			setSelectedItem('dataImports');
		} else if (path.includes(paths.cash4.balances.href)) {
			setSelectedItem('balances');
		} else if (path.includes(paths.cash4.reconciliations.href)) {
			setSelectedItem('reconciliations');
		} else if (
			sigmaDashboardEnabled &&
			path.includes(paths.cash4.dashboard.href)
		) {
			setSelectedItem('dashboard');
		} else {
			setSelectedItem('');
		}
	}, [location, sigmaDashboardEnabled]);

	useEffect(() => {
		const navigationItems: NavigationItemProps[] = [];

		if (sigmaDashboardEnabled) {
			navigationItems.push({
				id: 'dashboard-navigation-button',
				to: generatePath(paths.cash4.dashboard.href),
				label: 'Dashboard',
				icon: <Home />,
				selected: selectedItem === 'dashboard',
			});
		}

		const cash4NavigationItems: NavigationItemProps[] = [
			{
				id: 'balances-sidebar-navigation',
				to: generatePath(paths.cash4.balances.href),
				label: 'Balances',
				selected: selectedItem === 'balances',
			},
			{
				id: 'transactions-sidebar-navigation',
				to: generatePath(paths.cash4.transactions.href),
				label: 'Transactions',
				selected: selectedItem === 'transactions',
			},
		];
		if (projectedTransactionsEnabled) {
			cash4NavigationItems.push({
				id: 'reconciliations-sidebar-navigation',
				to: generatePath(paths.cash4.reconciliations.href),
				label: 'Reconciliations',
				selected: selectedItem === 'reconciliations',
			});
		}
		cash4NavigationItems.push(
			{
				id: 'categories-sidebar-navigation',
				to: generatePath(paths.cash4.categories.href),
				label: 'Categories',
				selected: selectedItem === 'categories',
			},
			{
				id: 'rules-sidebar-navigation',
				to: generatePath(paths.cash4.rules.href),
				label: 'Rules',
				selected: selectedItem === 'rules',
			},
		);

		navigationItems.push(
			{
				id: 'cash4-sidebar-navigation',
				label: 'Cash',
				icon: <Balance />,
				children: cash4NavigationItems,
			},
			{
				id: 'investments-sidebar-navigation',
				label: 'Investments',
				icon: <TrendingUp />,
				children: [
					{
						id: 'holdings-sidebar-navigation',
						to: generatePath(paths.cash4.investments.holdings.href),
						label: 'Holdings',
						selected: selectedItem === 'holdings',
					},
				],
			},
		);

		if (investmentTransactionsEnabled) {
			navigationItems[navigationItems.length - 1].children!.push({
				id: 'investment-transactions-sidebar-navigation',
				to: generatePath(paths.cash4.investments.transactions.href),
				label: 'Transactions',
				selected: selectedItem === 'investment-transactions',
			});
		}

		if (sigmaStudioEnabled && !hidePowerBi) {
			navigationItems.push({
				id: 'analytics-sidebar-navigation',
				label: 'Analytics Studio',
				icon: <BackupTable />,
				children: [
					{
						id: 'analytics-studio-sidebar-navigation',
						to: generatePath(paths.cash4.analytics.href),
						label: 'Classic',
						selected: selectedItem === 'analyticsStudio',
					},
					{
						id: 'analytics-studio-beta-sidebar-navigation',
						to: generatePath(paths.cash4.analyticsStudioBeta.href),
						label: 'Beta',
						selected: selectedItem === 'sigmaStudio',
					},
				],
			});
		} else if (sigmaStudioEnabled) {
			navigationItems.push({
				id: 'analytics-studio-beta-sidebar-navigation',
				to: generatePath(paths.cash4.analyticsStudioBeta.href),
				label: 'Analytics Studio',
				icon: <BackupTable />,
				selected: selectedItem === 'sigmaStudio',
			});
		} else if (!hidePowerBi) {
			navigationItems.push({
				id: 'analytics-studio-sidebar-navigation',
				to: generatePath(paths.cash4.analytics.href),
				label: 'Analytics Studio',
				icon: <BackupTable />,
				selected: selectedItem === 'analyticsStudio',
			});
		}

		if ((configurations?.enabledMoneyMarkets?.length ?? 0) > 0) {
			const moneyMarketItems: NavigationItemProps[] =
				configurations?.enabledMoneyMarkets
					?.sort((a, b) => a.name.localeCompare(b.name))
					?.map((x) => {
						const cleanedName = x.name.toLowerCase().replaceAll(' ', '-');

						return {
							id: cleanedName,
							to: window.location.pathname,
							label: x.name,
							selected: selectedItem === cleanedName,
							onClick: () => window.open(x.url, '_blank'),
						};
					}) ?? [];

			navigationItems.push({
				id: 'portals-sidebar-navigation',
				to: generatePath(paths.cash4.portals.href),
				label: 'Portals',
				icon: <ScreenshotMonitor />,
				selected: selectedItem === 'portals',
				children: moneyMarketItems,
			});
		}

		if (isIntegrationsAdmin) {
			navigationItems.push({
				id: 'account-integrations-sidebar-navigation',
				to: generatePath(paths.cash4.accountIntegrations.accounts.href),
				label: 'Account Integrations',
				icon: <SettingsInputComponent />,
				selected: selectedItem === 'accountIntegrations',
			});
		}

		if (isDataImporter) {
			navigationItems.push({
				id: 'data-imports-navigation',
				to: generatePath(paths.cash4.dataImports.href),
				label: 'Data Imports',
				icon: <Input />,
				selected: selectedItem === 'dataImports',
			});
		}

		setNavigationItems(navigationItems);
	}, [
		isIntegrationsAdmin,
		isDataImporter,
		selectedItem,
		sigmaStudioEnabled,
		investmentTransactionsEnabled,
		location.pathname,
		projectedTransactionsEnabled,
		sigmaDashboardEnabled,
		configurations?.enabledMoneyMarkets,
		hidePowerBi,
	]);

	const accessDeniedPage = (
		<CannotDisplay
			headingText={ACCESS_DENIED_MESSAGING.HEADING}
			bodyText={ACCESS_DENIED_MESSAGING.BODY}
			imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
		/>
	);

	return (
		<Layout
			appBar={
				<Navbar logo={<SolutionPicker activeSolution={T4Solution.Cash4} />} />
			}
			navElements={
				<NavigationMenu
					sections={[
						{
							navigationItems: navigationItems,
						},
					]}
				/>
			}
		>
			{isAuthor || isViewer ? (
				<Switch>
					<Route path={paths.cash4.href} exact>
						{sigmaDashboardEnabled ? (
							<Redirect to={paths.cash4.dashboard.href} />
						) : (
							<Redirect to={paths.cash4.balances.href} />
						)}
					</Route>
					{sigmaDashboardEnabled && (
						<Route exact path={paths.cash4.dashboard.href}>
							<SigmaDashboardProvider code={'cash4'}>
								<DashboardPageV2 />
							</SigmaDashboardProvider>
						</Route>
					)}
					<Route path={paths.cash4.balances.href} exact>
						<AccountIntegrationsProvider>
							<BalancesPage />
						</AccountIntegrationsProvider>
					</Route>
					<Route
						path={`${paths.cash4.balances.href}/:balanceId`.concat(
							validIdRegex,
						)}
						component={BalancesPage}
						exact
					/>
					<Route path={paths.cash4.transactions.href} exact>
						<DataProvider>
							<ReconciliationsProvider>
								<TransactionsProvider>
									<AccountIntegrationsProvider>
										<TransactionsPage />
									</AccountIntegrationsProvider>
								</TransactionsProvider>
							</ReconciliationsProvider>
						</DataProvider>
					</Route>
					<Route
						path={`${paths.cash4.transactions.href}/:transactionId`.concat(
							validIdRegex,
						)}
					>
						<DataProvider>
							<ReconciliationsProvider>
								<TransactionsProvider>
									<TransactionsPage />
								</TransactionsProvider>
							</ReconciliationsProvider>
						</DataProvider>
					</Route>
					{projectedTransactionsEnabled && (
						<Route path={paths.cash4.reconciliations.href}>
							<DataProvider>
								<ReconciliationsProvider>
									<ReconciliationsPage />
								</ReconciliationsProvider>
							</DataProvider>
						</Route>
					)}
					<Route
						path={`${paths.cash4.projectedTransactions.href}/:projectionId`.concat(
							validIdRegex,
						)}
					>
						<DataProvider>
							<ReconciliationsProvider>
								<TransactionsProvider>
									<TransactionsPage />
								</TransactionsProvider>
							</ReconciliationsProvider>
						</DataProvider>
					</Route>
					<Route
						path={paths.cash4.categories.href}
						component={CategoriesRoute}
						exact
					/>
					<Route path={paths.cash4.rules.href} component={RulesRoute} exact />
					<Route
						path={`${paths.cash4.rules.href}/:ruleId`.concat(validIdRegex)}
						component={RulesRoute}
					/>
					<Route
						path={`${paths.cash4.rules.href}/create`}
						component={RulesRoute}
						exact
					/>
					<Route
						path={paths.cash4.investments.holdings.href}
						component={HoldingsRoute}
						exact
					/>
					<Route
						path={paths.cash4.investments.transactions.href}
						component={InvestmentTransactionsRoute}
						exact
					/>
					{!hidePowerBi && (
						<Route
							path={paths.cash4.analytics.href}
							component={AnalyticsStudioPage}
							exact
						/>
					)}
					<Route path={paths.cash4.analyticsStudioBeta.href} exact>
						<SigmaPage solution={'Cash4'} />
					</Route>
					<Route path={paths.cash4.accountIntegrations.href}>
						{isIntegrationsAdmin ? (
							<AccountIntegrationsPage />
						) : (
							accessDeniedPage
						)}
					</Route>
					<Route path={paths.cash4.dataImports.href} exact>
						{isDataImporter ? <DataImportsPage /> : accessDeniedPage}
					</Route>
					<Route path={paths.cash4.portals.href}>
						<PortalsRoute />
					</Route>
					<Route>
						<CannotDisplay
							headingText={NOT_FOUND_MESSAGING.HEADING}
							bodyText={NOT_FOUND_MESSAGING.BODY}
							imageSrc={NOT_FOUND_MESSAGING.IMAGE}
							buttonText={RETURN_TO_HOME}
							buttonHref={paths.root.href}
						/>
					</Route>
				</Switch>
			) : (
				accessDeniedPage
			)}
		</Layout>
	);
});
