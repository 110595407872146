import { observer } from 'mobx-react-lite';
import { createContext, FC, useContext, useState } from 'react';

//#region Context

type VisualizationContextProps = {
	zoom: number;
	setZoom: (zoom: number) => void;
};

const VisualizationContext = createContext({} as VisualizationContextProps);

//#endregion

//#region Provider

export const VisualizationProvider: FC = observer(({ children }) => {
	const [zoom, setZoom] = useState(1);

	return (
		<VisualizationContext.Provider value={{ zoom, setZoom }}>
			{children}
		</VisualizationContext.Provider>
	);
});

//#endregion

//#region Hook

export type UseVisualizationProps = VisualizationContextProps;

export function useVisualization(): UseVisualizationProps {
	return useContext(VisualizationContext);
}

//#endregion
