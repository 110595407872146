import { Add } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { formatCurrency } from 'utilities/currencyUtils';
import { C4AlertBold } from '../_components/c4AlertBold';
import { C4ReconciliationsDateRangePicker } from '../_components/c4ReconciliationsDateRangePicker';
import { NotesField } from '../_components/notesField';
import { ProjectedItemsGrid } from '../_components/projectedItemsGrid';
import { ReconciliationTab } from '../_components/reconciliationDrawer';
import { ReportedItemsGrid } from '../_components/reportedItemsGrid';
import { SelectedTotal } from '../_components/selectedTotal';
import { useReconciliationCalculations } from '../_hooks/useReconciliationCalculations';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';

export type ModifyReconciliationViewProps = {
	selectedProjectedIds: string[];
	selectedReportedIds: string[];
	setSelectedProjectedIds: (ids: string[]) => void;
	setSelectedReportedIds: (ids: string[]) => void;
};

export const ModifyReconciliationView: FC<ModifyReconciliationViewProps> =
	observer(
		({
			selectedProjectedIds,
			selectedReportedIds,
			setSelectedProjectedIds,
			setSelectedReportedIds,
		}) => {
			const {
				projectedItemsQueryContext: {
					loading: projectedItemsLoading,
					data: projectedItems,
                    refetch: refetchProjectedItems,
				},
				reportedItemsQueryContext: {
					loading: reportedItemsLoading,
					data: reportedItems,
                    refetch: refetchReportedItems,
				},
				tab,
				reconciliation,
				setProjectedItemDrawerOpen,
                open: reconciliationDrawerOpen,
			} = useReconciliationsContext();

            useEffect(() => {
							if (reconciliationDrawerOpen) {
								refetchProjectedItems();
                                refetchReportedItems();
							}
						}, [reconciliationDrawerOpen, refetchProjectedItems, refetchReportedItems]);

			const projectedItemsActual = useMemo(() => {
				if (reconciliation) {
					return [...reconciliation.projectedItems, ...projectedItems];
				} else {
					return projectedItems;
				}
			}, [projectedItems, reconciliation]);

			const reportedItemsActual = useMemo(() => {
				if (reconciliation) {
					return [...reconciliation.reportedItems, ...reportedItems];
				} else {
					return reportedItems;
				}
			}, [reconciliation, reportedItems]);

			const selectedProjectedItems = useMemo(() => {
				return projectedItemsActual.filter((item) =>
					selectedProjectedIds.includes(item.id),
				);
			}, [selectedProjectedIds, projectedItemsActual]);
			const selectedReportedItems = useMemo(() => {
				return reportedItemsActual.filter((item) =>
					selectedReportedIds.includes(item.id),
				);
			}, [selectedReportedIds, reportedItemsActual]);
			
			const { projectedAmount, reportedAmount, difference, variance } =
				useReconciliationCalculations({
					id: reconciliation?.id ?? '',
					status: reconciliation?.status ?? '',
					createdBy: reconciliation?.createdBy ?? '',
					createdOn: reconciliation?.createdOn ?? '',
					projectedItems: selectedProjectedItems,
					reportedItems: selectedReportedItems,
				});

			return (
				<TabContext value={tab.toString()}>
					<TabPanel
						value={ReconciliationTab.Selection.toString()}
						sx={{ height: '100%', width: '100%', padding: 0 }}
					>
						<Grid
							container
							item
							xs={12}
							sx={{
								height: '100%',
								gap: 2,
								flexDirection: 'column',
							}}
						>
							<Grid
								container
								item
								xs={true}
								sx={{ gap: 2, flexWrap: 'nowrap' }}
							>
								<Grid
									container
									item
									xs={6}
									sx={{ gap: 2, flexDirection: 'column', flexWrap: 'nowrap' }}
								>
									<Grid
										container
										item
										xs="auto"
										sx={{ minHeight: '48px', justifyContent: 'space-between' }}
									>
										<Grid container item xs="auto">
											<Typography variant="h4" sx={{ alignSelf: 'flex-end' }}>
												Projected Items
											</Typography>
										</Grid>
										<Grid container item xs="auto">
											<Button
												variant="outlined"
												startIcon={<Add />}
												onClick={() => setProjectedItemDrawerOpen(true)}
												sx={{ alignSelf: 'flex-end' }}
											>
												Create Projected Item
											</Button>
										</Grid>
									</Grid>
									<Grid item xs={true}>
										<ActuallyPrettyGoodDataGridWrapper>
											<ProjectedItemsGrid
												stonlyId="projected-items-selection"
												tableKey="projected-items-selection"
												loading={projectedItemsLoading}
												projectedItems={projectedItemsActual}
												selectedProjectedIds={selectedProjectedIds}
												setSelectedProjectedIds={setSelectedProjectedIds}
											/>
										</ActuallyPrettyGoodDataGridWrapper>
									</Grid>
									<Grid item xs="auto">
										<SelectedTotal
											amount={projectedAmount}
											currencyCode={undefined}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									item
									xs={6}
									sx={{ gap: 2, flexDirection: 'column', flexWrap: 'nowrap' }}
								>
									<Grid
										container
										item
										xs="auto"
										sx={{ minHeight: '48px', justifyContent: 'space-between' }}
									>
										<Grid container item xs="auto">
											<Typography variant="h4" sx={{ alignSelf: 'flex-end' }}>
												Reported Items
											</Typography>
										</Grid>
										<Grid item xs="auto">
											<C4ReconciliationsDateRangePicker />
										</Grid>
									</Grid>
									<Grid item xs={true}>
										<ActuallyPrettyGoodDataGridWrapper>
											<ReportedItemsGrid
												stonlyId="reported-items-selection"
												tableKey="reoprted-items-selection"
												loading={reportedItemsLoading}
												reportedItems={reportedItemsActual}
												selectedReportedIds={selectedReportedIds}
												setSelectedReportedIds={setSelectedReportedIds}
											/>
										</ActuallyPrettyGoodDataGridWrapper>
									</Grid>
									<Grid item xs="auto">
										<SelectedTotal
											amount={reportedAmount}
											currencyCode={undefined}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</TabPanel>
					<TabPanel
						value={ReconciliationTab.Detail.toString()}
						sx={{ height: '100%', width: '100%', padding: 0 }}
					>
						<Grid
							container
							item
							xs={12}
							sx={{
								padding: 0,
								height: '100%',
								gap: 2,
								flexDirection: 'column',
							}}
						>
							<Grid
								container
								item
								xs={true}
								sx={{ gap: 2, flexWrap: 'nowrap' }}
							>
								<Grid item xs={6}>
									<Grid
										container
										item
										xs={12}
										sx={{
											height: '100%',
											gap: 2,
											flexDirection: 'column',
											flexWrap: 'nowrap',
										}}
									>
										<Grid
											container
											item
											xs="auto"
											sx={{
												minHeight: '48px',
												justifyContent: 'space-between',
											}}
										>
											<Grid container item xs="auto">
												<Typography variant="h4" sx={{ alignSelf: 'flex-end' }}>
													Projected Items
												</Typography>
											</Grid>
											<Grid container item xs="auto">
												<Button
													variant="outlined"
													startIcon={<Add />}
													onClick={() => setProjectedItemDrawerOpen(true)}
													sx={{ alignSelf: 'flex-end' }}
												>
													Create Projected Item
												</Button>
											</Grid>
										</Grid>
										<Grid item xs={true}>
											<ActuallyPrettyGoodDataGridWrapper>
												<ProjectedItemsGrid
													stonlyId="projected-items-selected"
													tableKey="projected-items-selected"
													projectedItems={selectedProjectedItems}
												/>
											</ActuallyPrettyGoodDataGridWrapper>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={6}>
									<Grid
										container
										item
										xs={12}
										sx={{
											height: '100%',
											gap: 2,
											flexDirection: 'column',
											flexWrap: 'nowrap',
										}}
									>
										<Grid item container xs="auto" sx={{ minHeight: '48px' }}>
											<Typography variant="h3" sx={{ alignSelf: 'flex-end' }}>
												Reported Items
											</Typography>
										</Grid>
										<Grid item xs={true}>
											<ActuallyPrettyGoodDataGridWrapper>
												<ReportedItemsGrid
													stonlyId="reported-items-selected"
													tableKey="reoprted-items-selected"
													reportedItems={selectedReportedItems}
												/>
											</ActuallyPrettyGoodDataGridWrapper>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs="auto">
								<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
									{`The selected records have an unreconciled amount of `}
									<C4AlertBold>{`${formatCurrency(difference, {
										currency: 'USD',
									})} USD`}</C4AlertBold>
									{'. The calculated variance is '}
									<C4AlertBold>{`${variance}%`}</C4AlertBold>
									{'.'}
								</T4Alert>
							</Grid>
							<Grid item xs="auto">
								<NotesField />
							</Grid>
						</Grid>
					</TabPanel>
				</TabContext>
			);
		},
	);
