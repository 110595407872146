import { debounce, Grid } from '@mui/material';
import go from 'gojs';
import { ReactOverview } from 'gojs-react';
import { observer } from 'mobx-react-lite';
import { VisualizationPreferences } from 'modules/clients/customer-api/src/api/visualizations';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { T4View } from 'shared/components/t4View';
import { ACCOUNT_MAP_ERROR_MESSAGING } from 'shared/constants/cannotDisplayMessaging';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { accountMapPrefix } from 'stonly/pagePrefixes';
import { VisualizationsFooter } from '../_shared/_components/visualizationFooter';
import { useAccountVisualizations } from '../_shared/_hooks/useAccountVisualizations';
import { useVisualization } from '../_shared/_providers/visualizationProvider';
import { useAccountMap } from './_providers/accountMapProvider';
import { AccountMapAccountViewRenderer } from './components/accountMapAccountViewRenderer';
import { AccountMapEntityViewRenderer } from './components/accountMapEntityViewRenderer';
import { AccountMapHeaderComponent } from './components/accountMapHeaderComponent';
import { AccountMapModel } from './models/accountMapModel';
import {
	AccountPurposeColors,
	AccountStatusColors,
	RegionColors,
} from './models/accountMapTypes';

export type RadioOption =
	| 'singleColor'
	| 'accountPurpose'
	| 'accountStatus'
	| 'entityRegion';

export type VisualizationAccount = {
	erpCode: string | undefined;
	accountCode: string;
	accountStatus: string | undefined;
	accountType: string | undefined;
	accountCurrencyCode: string | undefined;
	naturalAccountNumber: string | undefined;
	entityRegion: string | undefined;
	entityErpCode: string | undefined;
	counterparty: string | undefined;
	counterpartyBranch: string | undefined;

	key: string;
	group: string | undefined;
	isSubaccount: boolean;
	isForeignAccount: boolean;
	currentColor: string | undefined;
	isVisible: boolean;
	isAccountTypeVisible: boolean;
	isEntityRegionVisible: boolean;
	isAccountPurposeVisible: boolean;
	isAccountStatusVisible: boolean;
};

export type VisualizationLink = {
	key: string;
	from: string;
	to: string;
};

export const AccountMapPage: FC = observer(() => {
	const { visualizationAccountData } = useT4FeatureFlags();
	const {
		isLoading,
		error,
		accountMap,
		accountView: oldAccountMap,
		visualizationPreferences,
	} = useAccountMap();
	const { setZoom } = useVisualization();

	const [diagram, setDiagram] = useState<go.Diagram>();
	const [showOverviewMap, setShowOverviewMap] = useState<boolean>(true);

	const showAccountView = useMemo(
		() => oldAccountMap === 'account',
		[oldAccountMap],
	);

	const isFlagVisible = useMemo(
		() =>
			!(
				visualizationPreferences?.options?.find(
					(x) => x.optionId === 'displayFlag',
				)?.hide ?? false
			),
		[visualizationPreferences?.options],
	);

	const isAccountTypeVisible = useMemo(
		() =>
			!(
				visualizationPreferences?.options?.find(
					(x) => x.optionId === 'displayAccountType',
				)?.hide ?? false
			),
		[visualizationPreferences?.options],
	);

	const isStandaloneAccountsVisible = useMemo(
		() =>
			!(
				visualizationPreferences?.options?.find(
					(x) => x.optionId === 'displayStandaloneAccounts',
				)?.hide ?? false
			),
		[visualizationPreferences?.options],
	);

	const isSubaccountsVisible = useMemo(
		() =>
			!(
				visualizationPreferences?.options?.find(
					(x) => x.optionId === 'displaySubaccounts',
				)?.hide ?? true
			),
		[visualizationPreferences?.options],
	);

	const isClosedAccountsVisible = useMemo(
		() =>
			!(
				visualizationPreferences?.options?.find(
					(x) => x.optionId === 'displayClosedAccounts',
				)?.hide ?? false
			),
		[visualizationPreferences?.options],
	);

	//#region

	const getColorDisplayPreference = useCallback(
		(
			currentVisualizationPreferences:
				| VisualizationPreferences
				| undefined = visualizationPreferences,
		) => {
			const currentColorByPreference =
				currentVisualizationPreferences?.options?.find(
					(x) => x.optionId === 'cardOptionsColor',
				)?.value ?? 'accountPurpose';

			let colorByPreference: RadioOption = 'accountPurpose';
			switch (currentColorByPreference) {
				case 'singleColor':
					colorByPreference = 'singleColor';
					break;
				case 'accountPurpose':
					colorByPreference = 'accountPurpose';
					break;
				case 'accountStatus':
					colorByPreference = 'accountStatus';
					break;
				case 'entityRegion':
					colorByPreference = 'entityRegion';
					break;
			}

			return colorByPreference;
		},
		[visualizationPreferences],
	);

	const isFieldVisible = useCallback(
		(property: string) => {
			return !(
				visualizationPreferences?.options?.find((x) => x.optionId === property)
					?.hide ?? false
			);
		},
		[visualizationPreferences?.options],
	);

	const isEntityRegionVisible = useMemo(
		() => getColorDisplayPreference() === 'entityRegion',
		[getColorDisplayPreference],
	);

	const isAccountPurposeVisible = useMemo(
		() => getColorDisplayPreference() === 'accountPurpose',
		[getColorDisplayPreference],
	);

	const isAccountStatusVisible = useMemo(
		() => getColorDisplayPreference() === 'accountStatus',
		[getColorDisplayPreference],
	);

	const getNodeColor = useCallback(
		(data: {
			purpose: string | undefined;
			accountStatus: string | undefined;
			entityRegion: string | undefined;
		}) => {
			let color: string = 'white';
			if (visualizationPreferences) {
				const colorByPreference = getColorDisplayPreference();

				const userDefinedColor =
					visualizationPreferences.options?.find(
						(x) => x.optionId === 'userDefinedColor',
					)?.value ?? 'white';

				if (colorByPreference === 'singleColor' && userDefinedColor) {
					color = userDefinedColor;
				} else {
					switch (colorByPreference) {
						case 'accountPurpose':
							const accountPurposeColor = AccountPurposeColors.get(
								data['purpose'] ?? '',
							);
							if (accountPurposeColor) {
								color = accountPurposeColor;
							}
							break;

						case 'accountStatus':
							const accountStatusColor = AccountStatusColors.get(
								data['accountStatus'] ?? '',
							);
							if (accountStatusColor) {
								color = accountStatusColor;
							}
							break;

						case 'entityRegion':
							const entityRegionColor = RegionColors.get(
								data['entityRegion'] ?? '',
							);
							if (entityRegionColor) {
								color = entityRegionColor;
							}
							break;
					}
				}
			}

			return color;
		},
		[getColorDisplayPreference, visualizationPreferences],
	);

	const { accountView, entityAccountView } = useAccountVisualizations({
		isFlagVisible,
		isSubaccountsVisible,
		isClosedAccountsVisible,
		isAccountTypeVisible,
		isEntityRegionVisible,
		isAccountPurposeVisible,
		isAccountStatusVisible,
		getNodeColor,
	});

	const viewModel = useMemo(() => {
		return new AccountMapModel(
			accountMap,
			isFlagVisible,
			isStandaloneAccountsVisible,
			isSubaccountsVisible,
			isClosedAccountsVisible,
			isAccountTypeVisible,
			isEntityRegionVisible,
			isAccountPurposeVisible,
			isAccountStatusVisible,
			getNodeColor,
		);
	}, [
		accountMap,
		getNodeColor,
		isAccountPurposeVisible,
		isAccountStatusVisible,
		isAccountTypeVisible,
		isClosedAccountsVisible,
		isEntityRegionVisible,
		isFlagVisible,
		isStandaloneAccountsVisible,
		isSubaccountsVisible,
	]);

	const applyDisplayStandaloneAccountsPreference = useCallback(
		(
			currentPreferences: VisualizationPreferences,
			currentDiagram: go.Diagram | undefined,
			m: go.Model,
		) => {
			if (currentDiagram && currentPreferences) {
				const orphanGroup = currentDiagram
					.findNodesByExample({ text: 'Standalone Accounts' })
					.first();
				if (orphanGroup) {
					m.set(
						orphanGroup,
						'visible',
						!(
							currentPreferences.options?.find(
								(x) => x.optionId === 'displayStandaloneAccounts',
							)?.hide ?? false
						),
					);
				}
			}
		},
		[],
	);

	const applyDisplaySubaccountsPreference = useCallback(
		(
			currentPreferences: VisualizationPreferences,
			currentDiagram: go.Diagram | undefined,
			m: go.Model,
		) => {
			if (currentDiagram && currentPreferences) {
				currentDiagram
					.findNodesByExample({ isSubaccount: true })
					.each((node) => {
						m.set(
							node,
							'visible',
							!(
								currentPreferences.options?.find(
									(x) => x.optionId === 'displaySubaccounts',
								)?.hide ?? true
							),
						);
					});
			}
		},
		[],
	);

	const applyDisplayClosedAccountsPreference = useCallback(
		(
			currentPreferences: VisualizationPreferences,
			currentDiagram: go.Diagram | undefined,
			m: go.Model,
		) => {
			if (currentDiagram && currentPreferences) {
				currentDiagram
					.findNodesByExample({ accountStatus: 'Closed' })
					.each((node) => {
						m.set(
							node,
							'visible',
							!currentPreferences.options?.find(
								(x) => x.optionId === 'displayClosedAccounts',
							)?.hide ?? false,
						);
					});
			}
		},
		[],
	);

	const applyDisplayLegendPreference = useCallback(
		(
			currentPreferences: VisualizationPreferences,
			currentDiagram: go.Diagram | undefined,
			m: go.Model,
		) => {
			if (currentDiagram) {
				const legendGroup = currentDiagram
					.findNodesByExample({ category: 'Legend' })
					.first();
				if (legendGroup) {
					m.set(
						legendGroup,
						'visible',
						!(
							currentPreferences.options?.find(
								(x) => x.optionId === 'displayLegend',
							)?.hide ?? false
						),
					);
				}
			}
		},
		[],
	);

	const applyDisplayOverviewMapPreference = useCallback(
		(currentPreferences: VisualizationPreferences) => {
			if (currentPreferences) {
				setShowOverviewMap(
					!(
						currentPreferences.options?.find(
							(x) => x.optionId === 'displayOverviewMap',
						)?.hide ?? false
					),
				);
			}
		},
		[],
	);

	const applyColorPreference = useCallback(
		(
			currentPreferences: VisualizationPreferences,
			currentDiagram: go.Diagram | undefined,
			currentModel: go.Model | undefined,
		) => {
			if (currentDiagram && currentModel && currentPreferences) {
				const currentColorByPreference =
					currentPreferences.options?.find(
						(x) => x.optionId === 'cardOptionsColor',
					)?.value ?? 'accountPurpose';

				let colorByPreference: RadioOption = 'accountPurpose';
				switch (currentColorByPreference) {
					case 'singleColor':
						colorByPreference = 'singleColor';
						break;
					case 'accountPurpose':
						colorByPreference = 'accountPurpose';
						break;
					case 'accountStatus':
						colorByPreference = 'accountStatus';
						break;
					case 'entityRegion':
						colorByPreference = 'entityRegion';
						break;
				}

				//#region Update Legend

				currentDiagram.nodes
					.filter((x) => x.category === 'LegendNode')
					.each((node) => {
						[
							'isAccountPurposeVisible',
							'isAccountStatusVisible',
							'isEntityRegionVisible',
						].forEach((property) => {
							let isVisible = false;

							switch (colorByPreference) {
								case 'accountPurpose':
									if (property === 'isAccountPurposeVisible') {
										isVisible = true;
									}
									break;

								case 'accountStatus':
									if (property === 'isAccountStatusVisible') {
										isVisible = true;
									}
									break;

								case 'entityRegion':
									if (property === 'isEntityRegionVisible') {
										isVisible = true;
									}
									break;
							}

							currentModel.set(node.data, property, isVisible);
						});
					});

				//#endregion

				//#region Update Nodes

				currentDiagram.nodes
					.filter((x) => x.data.entityId)
					.each((node) => {
						currentModel.set(
							node.data,
							'currentColor',
							getNodeColor(node.data),
						);
					});

				//#endregion
			}
		},
		[getNodeColor],
	);

	const applyDisplayFlagPreference = useCallback(
		(
			currentPreferences: VisualizationPreferences,
			currentDiagram: go.Diagram | undefined,
			currentModel: go.Model | undefined,
		) => {
			if (currentDiagram && currentModel) {
				currentDiagram
					.findNodesByExample({ category: 'entityOrg' })
					.each((entityOrg) => {
						currentModel.set(
							entityOrg.data,
							'isFlagVisible',
							!(
								currentPreferences.options?.find(
									(x) => x.optionId === 'displayFlag',
								)?.hide ?? false
							),
						);
					});
			}
		},
		[],
	);

	const applyDisplayAccountTypePreference = useCallback(
		(
			currentPreferences: VisualizationPreferences,
			currentDiagram: go.Diagram | undefined,
			currentModel: go.Model | undefined,
		) => {
			if (currentDiagram && currentModel) {
				currentDiagram.nodes.each((node) => {
					currentModel.set(
						node.data,
						'isAccountTypeVisible',
						!(
							currentPreferences.options?.find(
								(x) => x.optionId === 'displayAccountType',
							)?.hide ?? false
						),
					);
				});
			}
		},
		[],
	);

	const applyInformationPreferences = useCallback(
		(
			currentPreferences: VisualizationPreferences,
			currentDiagram: go.Diagram | undefined,
			m: go.Model,
		) => {
			const informationOptions = [
				'accountStatus',
				'openDate',
				'closeDate',
				'accountType',
				'purpose',
				'counterpartyBranch',
				'entityRegion',
				'entityErpCode',
				'generalLedgerAccountNumber',
			];

			if (currentPreferences && currentDiagram) {
				const informationPreferences =
					currentPreferences.options?.filter((x) =>
						informationOptions.includes(x.optionId),
					) ?? [];
				currentDiagram.nodes.each((n) => {
					informationPreferences.forEach((p) => {
						const rowPanel = n.findObject(p.optionId + 'p');
						if (rowPanel !== null) {
							m.set(rowPanel, 'visible', !p.hide);
						}
					});
				});
			}
		},
		[],
	);

	const loadPreferences = useCallback(
		(
			currentPreferences:
				| VisualizationPreferences
				| undefined = visualizationPreferences,
			currentDiagram: go.Diagram | undefined = diagram,
		) => {
			if (currentPreferences && currentDiagram) {
				currentDiagram.commit((d) => {
					d.model.commit((m) => {
						applyDisplaySubaccountsPreference(currentPreferences, d, m);
						applyDisplayStandaloneAccountsPreference(currentPreferences, d, m);
						applyDisplayClosedAccountsPreference(currentPreferences, d, m);
						applyDisplayLegendPreference(currentPreferences, d, m);
						applyDisplayOverviewMapPreference(currentPreferences);
						applyDisplayAccountTypePreference(currentPreferences, d, m);
						applyColorPreference(currentPreferences, d, m);
						applyDisplayFlagPreference(currentPreferences, d, m);
						applyInformationPreferences(currentPreferences, d, m);
					});
				});
			}
		},
		[
			applyColorPreference,
			applyDisplayAccountTypePreference,
			applyDisplayClosedAccountsPreference,
			applyDisplayFlagPreference,
			applyDisplayLegendPreference,
			applyDisplayOverviewMapPreference,
			applyDisplayStandaloneAccountsPreference,
			applyDisplaySubaccountsPreference,
			applyInformationPreferences,
			diagram,
			visualizationPreferences,
		],
	);

	//#endregion

	const onViewportBoundsChangedHandler = useMemo(
		() =>
			debounce((event: go.DiagramEvent) => {
				setZoom(event.diagram.scale);
			}, 100),
		[setZoom],
	);

	useEffect(() => {
		loadPreferences(visualizationPreferences, diagram);
	}, [diagram, loadPreferences, visualizationPreferences]);

	if (!visualizationAccountData && error) {
		return (
			<CannotDisplay
				headingText={ACCOUNT_MAP_ERROR_MESSAGING.HEADING}
				bodyText={ACCOUNT_MAP_ERROR_MESSAGING.BODY}
				imageSrc={ACCOUNT_MAP_ERROR_MESSAGING.IMAGE}
				buttonHref={ACCOUNT_MAP_ERROR_MESSAGING.BUTTON_HREF}
				buttonText={ACCOUNT_MAP_ERROR_MESSAGING.BUTTON_TEXT}
			/>
		);
	}

	return (
		<T4View loading={isLoading} disablePadding>
			<Grid
				container
				flexDirection="column"
				sx={{ height: '100%', width: '100%', overflow: 'hidden' }}
			>
				<Grid item xs="auto">
					<AccountMapHeaderComponent diagram={diagram} />
				</Grid>
				<Grid
					item
					xs={true}
					sx={{
						position: 'relative',
						boxSizing: 'unset',

						canvas: {
							outline: 'none',
						},
						'.accountview-gojs-diagram, .entityview-gojs-diagram': {
							width: '100%',
							height: '100%',

							'& .canvas': {
								outline: 'none',
							},
						},
					}}
				>
					{showOverviewMap && (
						<ReactOverview
							initOverview={() =>
								new go.Overview(undefined, {
									contentAlignment: go.Spot.Center,
								})
							}
							divClassName=""
							style={{
								backgroundColor: '#eee',
								height: '150px',
								width: '250px',
								position: 'absolute',
								top: '2rem',
								left: '2rem',
								border: 'solid',
								borderWidth: '1px',
								borderColor: 'black',
								zIndex: 10,
							}}
							observedDiagram={diagram ?? null}
						/>
					)}
					{showAccountView && (
						<AccountMapAccountViewRenderer
							nodeDataArray={
								visualizationAccountData
									? accountView.nodes
									: viewModel.accountViewNodes
							}
							linkDataArray={
								visualizationAccountData
									? accountView.links
									: viewModel.accountViewLinks
							}
							modelData={{ canRelink: false }}
							initialLayoutCompleted={(event) => {
								setDiagram(event.diagram);
							}}
							viewPortBoundsChanged={onViewportBoundsChangedHandler}
							isFieldVisible={isFieldVisible}
							isOrphanGroupVisible={isStandaloneAccountsVisible}
						/>
					)}
					{!showAccountView && (
						<AccountMapEntityViewRenderer
							nodeDataArray={
								visualizationAccountData
									? entityAccountView.nodes
									: viewModel.entityViewNodes
							}
							linkDataArray={
								visualizationAccountData
									? entityAccountView.links
									: viewModel.entityViewLinks
							}
							modelData={{ canRelink: false }}
							backgroundSingleClicked={() => {
								loadPreferences(visualizationPreferences);
							}}
							initialLayoutCompleted={(event) => {
								setDiagram(event.diagram);
								loadPreferences(visualizationPreferences, event.diagram);
							}}
							viewPortBoundsChanged={onViewportBoundsChangedHandler}
							isFieldVisible={isFieldVisible}
							isOrphanGroupVisible={isStandaloneAccountsVisible}
						/>
					)}
				</Grid>
				<Grid
					item
					xs="auto"
					sx={{
						paddingBottom: '1rem',
						justifyContent: 'center',
						position: 'absolute',
						bottom: '1rem',
						zIndex: 5,
					}}
				>
					<VisualizationsFooter
						stonlyId={accountMapPrefix}
						diagram={diagram}
						onResetView={(d) => loadPreferences(visualizationPreferences, d)}
					/>
				</Grid>
			</Grid>
		</T4View>
	);
});
