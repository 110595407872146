/* eslint-disable mobx/missing-observer */
import { legend } from 'features/entity4/visualizations/_shared/_templates/legend';
import * as go from 'gojs';
import { Panel } from 'gojs';
import { ReactDiagram } from 'gojs-react';
import * as React from 'react';
import './hyperlinkText';
import './roundedRectangles';

export type T4DiagramProps = {
	nodeDataArray: Array<go.ObjectData>;
	linkDataArray: Array<go.ObjectData>;
	modelData: go.ObjectData;
	isOrphanGroupVisible: boolean;
	initialLayoutCompleted?: (e: go.DiagramEvent) => void;
	onModelChange?: (e: go.IncrementalData) => void;
	backgroundSingleClicked?: (e: go.DiagramEvent) => void;
	viewPortBoundsChanged?: (e: go.DiagramEvent) => void;
	isFieldVisible: (property: string) => boolean;
	diagramListeners?: {
		selectionMoved?: (e: go.DiagramEvent) => void;
		animationFinished?: (e: go.DiagramEvent) => void;
		animationStarted?: (e: go.DiagramEvent) => void;
	};
	onDoubleClick?: (event: go.InputEvent) => void;
};

export class GraphRendererBase extends React.Component<T4DiagramProps, {}> {
	public diagramRef: React.RefObject<ReactDiagram>;
	public diagramStyle = { backgroundColor: 'white' };
	private _legendAndOrphanFont = 'Bold 34pt Roboto,Helvetica,Arial,sans-serif';

	//this margin is admittedly weird and was the result of a bit of tinkering aroun.  The bottom of the "Standalone Accounts/Entities"
	// title has some mysterious bottom margin that seems to already be there.

	private _legendAndOrphanMargin = new go.Margin(14, 12, 0, 24);

	private _legendAndOrphanButtonMargin = new go.Margin(0, 24, 0, 0);
	protected _connectorStrokeWidth = 2;
	protected _lineSeparatorMargin = new go.Margin(8, 0, 8, 0);
	protected _tableTopMargin = new go.Margin(4, 8, 8, 8);
	protected _tableSeparatorPadding = new go.Margin(4, 0, 0, 0);
	protected _textColor = '#404142';

	/** @internal */
	constructor(props: T4DiagramProps) {
		super(props);

		this.diagramRef = React.createRef();
		this.createDiagramModel = this.createDiagramModel.bind(this);
		this.setDiagramEvents = this.setDiagramEvents.bind(this);
		this.createTableRow = this.createTableRow.bind(this);
		this.createMouseEvents = this.createMouseEvents.bind(this);
		this.createNodeTemplateBase = this.createNodeTemplateBase.bind(this);
		this.createLinkTemplate = this.createLinkTemplate.bind(this);
		this.createBasicNodeTemplate = this.createBasicNodeTemplate.bind(this);
		this.createOrphanGroupTemplate = this.createOrphanGroupTemplate.bind(this);
		this.createLegendGroupTemplate = this.createLegendGroupTemplate.bind(this);
		this.createLegendNode = this.createLegendNode.bind(this);
	}

	public createLinkTemplate(showTooltip: boolean = false) {
		const link = new go.Link({
			routing: go.Routing.AvoidsNodes,
			curve: go.Curve.JumpGap,
			corner: 5,
			copyable: false,
			deletable: false,
			selectionAdorned: false,
		}).add(
			new go.Shape()
				.bind(
					new go.Binding('strokeWidth', 'isSelected', (isSelected) =>
						isSelected ? 5 : this._connectorStrokeWidth,
					).ofObject(),
				)
				.bind('stroke', 'type', (type) => {
					if (type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				})
				.bind('fill', 'type', (type) => {
					if (type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				})
				.bind('strokeDashArray', '', (data) => {
					if (data.type === 'Subaccount' || data.movement === 'Manual') {
						return [4, 2];
					}

					return null;
				}),

			new go.Shape({
				fromArrow: '',
				scale: 1.5,
				fill: 'black',
				stroke: 'black',
			})
				.bind('fromArrow', '', (data) => {
					if (
						data.type === 'Two-Way' ||
						(data.type === 'One-Way' && data.isLeftSide === true)
					) {
						return 'BackwardTriangle';
					}

					return '';
				})
				.bind('stroke', 'type', (type) => {
					if (type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				})
				.bind('fill', 'type', (type) => {
					if (type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				}),

			new go.Shape({
				toArrow: '',
				scale: 1.5,
				fill: 'black',
				stroke: 'black',
			})
				.bind('toArrow', '', (data) => {
					if (
						data.type === 'Two-Way' ||
						(data.type === 'One-Way' && data.isLeftSide !== true)
					) {
						return 'Triangle';
					}

					if (data.type === 'Entity') {
						return 'Block';
					}

					return '';
				})
				.bind('stroke', 'type', (type) => {
					if (type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				})
				.bind('fill', 'type', (type) => {
					if (type === 'Subaccount') {
						return '#F6802C';
					}

					return 'black';
				}),

			new go.TextBlock({
				margin: 5,
				segmentIndex: NaN,
				segmentFraction: 2 / 3,
				segmentOffset: new go.Point(NaN, NaN),
				alignment: go.Spot.Right,
			}).bind('text', '', (data) => {
				let text = '';

				if (data.linkLabelsEnabled) {
					if (data.movement || data.type) {
						if (data.type) {
							text += `${data.type}`;
						}

						if (data.movement) {
							if (text) {
								text += '\n';
							}

							text += `${data.movement}`;
						}
					}
				}

				return text;
			}),
		);

		if (showTooltip) {
			link.toolTip = go.GraphObject.make(
				'ToolTip',
				go.GraphObject.make(
					go.TextBlock,
					{ margin: 4 },
					new go.Binding('text', 'movement', (movement) => {
						return !!movement
							? movement + ' Funding'
							: 'Funding Direction Unknown';
					}),
				),
			);
		}

		return link;
	}

	public createOrphanGroupTemplate(
		title: string,
		wrappingColumn: number = 3,
		isOrphanGroupVisible: boolean,
	) {
		return new go.Group(Panel.Auto, {
			layout: new go.GridLayout({
				wrappingColumn: wrappingColumn,
				spacing: new go.Size(8, 8),
			}),
			visible: isOrphanGroupVisible,
		})
			.add(
				new go.Shape('RoundedRectangle', { parameter1: 6, fill: 'white' }),
				new go.Panel('Vertical', { defaultAlignment: go.Spot.TopCenter }).add(
					new go.Panel('Horizontal').add(
						new go.TextBlock(title, {
							stroke: this._textColor,
							font: this._legendAndOrphanFont,
							margin: this._legendAndOrphanMargin,
							alignment: go.Spot.Left,
						}),
						go.GraphObject.make('SubGraphExpanderButton', {
							alignment: go.Spot.Left,
							width: 25,
							margin: this._legendAndOrphanButtonMargin,
						}),
					),
					new go.Shape('LineH', {
						stretch: go.Stretch.Horizontal,
						height: 0,
						margin: this._lineSeparatorMargin,
						stroke: '#e2e2e3',
						strokeWidth: 2,
					}),
					new go.Placeholder({
						padding: 8,
						background: 'white',
					}),
				),
			)
			.bind('location', 'loc');
	}

	//override this method to create a legend node
	public createLegendNode(diagram: go.Diagram) {}

	public createLegendGroupTemplate(diagram: go.Diagram) {
		diagram.groupTemplateMap.add('Legend', legend());

		this.createLegendNode(diagram);
	}

	public createMouseEvents(
		onDoubleClick?: (event: go.InputEvent) => void,
	): Pick<go.Node, 'mouseEnter' | 'mouseLeave' | 'doubleClick'> {
		const isControlPressedGoJS = (event: go.InputEvent) => {
			const isMac = navigator.userAgent.includes('Mac');

			return isMac ? event.meta : event.control;
		};

		return {
			mouseEnter: (event, current, _prev) => {
				if (isControlPressedGoJS(event)) {
					return;
				}

				let node: go.Node = current as go.Node;
				event.diagram.commit((diagram) => {
					diagram.focus(); //this allows for the shift key to work.
					if (event.shift && event.alt) {
						GraphRendererBase.recurseHighlightAllDescendants(node);
					} else if (event.shift) {
						GraphRendererBase.recurseHighlightAllAncestors(node);
					} else if (event.alt) {
						GraphRendererBase.highlightDirectConnections(node);
					}
				});
			},
			mouseLeave: (event, _current, _prev) => {
				if (isControlPressedGoJS(event)) {
					return;
				}

				event.diagram.commit((diagram) => {
					GraphRendererBase.clearAllHighlighted(diagram);
				});
			},
			doubleClick: (event, graphObject) => {
				event.diagram.commit((d) => {
					d.model.commit((m) => {
						if (graphObject instanceof go.Node) {
							m.set(graphObject, 'isSelected', true);
							graphObject.isSelected = true;
						}

						const hideNonSelectedPart = (part: go.Part) => {
							if (
								part.data.category === 'Legend' ||
								part.name === 'LegendNode'
							) {
								return;
							} else if (part.data.key === 'Standalone') {
								m.set(
									part,
									'visible',
									d.selection.filter((x) => x.data.group === 'Standalone')
										.count > 0,
								);
							} else if (!d.selection.contains(part)) {
								// m.set(part.data, 'isVisible', false);
								m.set(part, 'visible', false);
							}
						};
						// d.links.each(hideNonSelectedPart);
						d.nodes.each(hideNonSelectedPart);
					});
				});
				onDoubleClick?.(event);
			},
		};
	}

	public static highlightDirectConnections(node: go.Node) {
		node.diagram!.model.commit((m) => {
			m.set(node, 'isSelected', true);

			node.findNodesInto().each((n) => {
				m.set(n, 'isSelected', true);
			});

			node.findLinksInto().each((l) => {
				m.set(l, 'isSelected', true);
			});
			node.findLinksOutOf().each((l: go.Link) => {
				if (l.toNode && l.data.category?.includes('Two')) {
					//accommodate for two way links in the entity view
					m.set(l.toNode, 'isSelected', true);
					m.set(l, 'isSelected', true);
				}
			});
		});
	}

	//applies a boolean fn to all children of a node, and returns false if the fn returns false for any child.
	// if no children, returns true.
	public static recurseAllChildren(
		parent: go.Node,
		callback: (node: go.Node) => boolean,
	): boolean {
		let children = parent.findNodesOutOf();
		if (children.count === 0) {
			return true;
		}
		let ret = true;
		children.each((child) => {
			if (child instanceof go.Node) {
				if (
					!callback(child) ||
					!GraphRendererBase.recurseAllChildren(child, callback)
				) {
					ret = false;
					return;
				}
			}
		});
		return ret;
	}

	public static recurseHighlightAllAncestors(node: go.Node) {
		node.diagram!.model.commit((m) => {
			m.set(node, 'isSelected', true);
			let ancestors = GraphRendererBase.getAllAncestors(node);
			ancestors.forEach((p: go.Part) => {
				if (p instanceof go.Node || p instanceof go.Link) {
					m.set(p, 'isSelected', true);
				}
			});
		});
	}

	public static recurseHighlightAllDescendants(node: go.Node) {
		node.diagram!.model.commit((m) => {
			m.set(node, 'isSelected', true);
			let descendants = GraphRendererBase.getAllDescendants(node);
			descendants.forEach((p: go.Part) => {
				if (p instanceof go.Node || p instanceof go.Link) {
					m.set(p, 'isSelected', true);
				}
			});
		});
	}

	//getAllAncestors gets all ancestors of a selected node within a directed graph.
	//  Currently, entity view is the only one with two way links, denoted by a link with category containing the word
	//   "Two" in it.
	public static getAllAncestors(node: go.Node): go.Part[] {
		const ancestors: go.Part[] = [];
		const visitedNodes: go.Set<go.Node> = new go.Set<go.Node>();
		const visitedLinks: go.Set<go.Link> = new go.Set<go.Link>();

		let findAncestors = (node: go.Node) => {
			if (visitedNodes.contains(node)) {
				return; // Terminate recursion if node has already been visited
			}
			visitedNodes.add(node);

			// Add node to ancestors
			ancestors.push(node);

			// Find parent nodes and links
			let parentNodes: go.Set<go.Node> = new go.Set<go.Node>(
				node.findNodesInto(),
			);
			let parentLinks: go.Set<go.Link> = new go.Set<go.Link>(
				node.findLinksInto(),
			);

			node.findLinksOutOf().each((l: go.Link) => {
				if (l.data.category?.includes('Two')) {
					parentLinks.add(l);
					parentNodes.add(l.toNode!);
				}
			});

			// Add links to ancestors
			parentLinks.each((l: go.Link) => {
				if (!visitedLinks.contains(l)) {
					ancestors.push(l);
					visitedLinks.add(l);
				}
			});

			// Recursively find ancestors for parent nodes
			parentNodes.each((parent: go.Node) => {
				findAncestors(parent);
			});
		};

		findAncestors(node);
		return ancestors;
	}

	public static getAllDescendants(node: go.Node): go.Part[] {
		const descendants: go.Part[] = [];
		const visitedNodes: go.Set<go.Node> = new go.Set<go.Node>();
		const visitedLinks: go.Set<go.Link> = new go.Set<go.Link>();

		let findDescendants = (node: go.Node) => {
			if (visitedNodes.contains(node)) {
				return; // Terminate recursion if node has already been visited
			}
			visitedNodes.add(node);

			// Add node to ancestors
			descendants.push(node);

			// Find parent nodes and links
			let childNodes: go.Set<go.Node> = new go.Set<go.Node>(
				node.findNodesOutOf(),
			);
			let childLinks: go.Set<go.Link> = new go.Set<go.Link>(
				node.findLinksOutOf(),
			);

			node.findLinksOutOf().each((l: go.Link) => {
				if (l.data.category?.includes('Two')) {
					childLinks.add(l);
					childNodes.add(l.toNode!);
				}
			});

			// Add links to ancestors
			childLinks.each((l: go.Link) => {
				if (!visitedLinks.contains(l)) {
					descendants.push(l);
					visitedLinks.add(l);
				}
			});

			// Recursively find ancestors for parent nodes
			childNodes.each((parent: go.Node) => {
				findDescendants(parent);
			});
		};

		findDescendants(node);
		return descendants;
	}

	public static clearAllHighlighted(diagram: go.Diagram) {
		diagram.model.commit((m) => {
			diagram.nodes.each((node: go.Node) => {
				m.set(node, 'isSelected', false);
			});
			diagram.links.each((link: go.Link) => {
				m.set(link, 'isSelected', false);
			});
		});
	}

	public setDiagramEvents(diagram: go.Diagram) {
		const isControlPressedKB = (event: KeyboardEvent) => {
			const isMac = navigator.userAgent.includes('Mac');

			return isMac ? event.metaKey : event.ctrlKey;
		};

		diagram.addDiagramListener('AnimationStarting', (e) => {
			this.props.diagramListeners?.animationStarted?.(e);
		});

		diagram.addDiagramListener('SelectionMoved', (e) => {
			this.props.diagramListeners?.selectionMoved?.(e);
		});

		diagram.addDiagramListener('AnimationFinished', (e) => {
			this.props.diagramListeners?.animationFinished?.(e);
		});

		diagram.addDiagramListener('ViewportBoundsChanged', (event) => {
			this.props.viewPortBoundsChanged?.(event);
		});

		diagram.addDiagramListener('BackgroundSingleClicked', (event) => {
			this.props.backgroundSingleClicked?.(event);
		});

		this.props.initialLayoutCompleted &&
			diagram.addDiagramListener(
				'InitialLayoutCompleted',
				(e: go.DiagramEvent) => {
					// In React-land, GoJS fires InitialLayoutCompleted twice.
					// https://forum.nwoods.com/t/why-the-initiallayoutcompleted-event-is-fired-twice/14452/4
					// First, it will be fired prior to the data being initialized and then again after.
					// Suppress the first call by checking if modelData isn't initialized yet
					if (Object.keys(e.diagram.model.modelData).length !== 0) {
						this.props.initialLayoutCompleted?.(e);
					}
				},
			);
		//The reason this whole section exists is so that while a user is hovered a node, they can switch between the modes
		//  (e.g. shift/alt, etc.).  Without this, a user has to move out of the node, change their key combination, and then
		//  hover back in.  Without this entire section, the functionality will work (albeit requiring the user to hover out/in)
		//  because of the this.createMouseEvents() method above.  However, this is a better user experience.
		//  Both this and the this.createMouseEvents are required.

		//Note: an attempt was made to create a CustomToolManager which overrides doKeyDown and doKeyUP
		// as below and then set:
		//diagram.currentTool = diagram.defaultTool = new CustomToolManager();
		//  While this does work, it stops the click/double-click events from being received
		//  GoJS support is not sure why.

		const getNodeUnderMouse = (diagram: go.Diagram) => {
			// Get the current mouse position
			const mousePt = diagram.lastInput.documentPoint;
			// Find the node at the mouse position
			return diagram.findPartAt(mousePt, true);
		};
		const diagramDefaultKeyDown = diagram.defaultTool.doKeyDown;
		diagram.defaultTool.doKeyDown = function () {
			const event = diagram.lastInput.event;
			if (event instanceof KeyboardEvent) {
				let selectedNode = getNodeUnderMouse(diagram);
				// Ensure it's a node (not a link or other part)
				if (!(selectedNode instanceof go.Node)) {
					return;
				}
				if (!isControlPressedKB(event)) {
					if (event.shiftKey && event.altKey) {
						GraphRendererBase.clearAllHighlighted(diagram);
						GraphRendererBase.recurseHighlightAllDescendants(selectedNode);
					} else if (event.shiftKey) {
						GraphRendererBase.clearAllHighlighted(diagram);
						GraphRendererBase.recurseHighlightAllAncestors(selectedNode);
					} else if (event.altKey) {
						GraphRendererBase.clearAllHighlighted(diagram);
						GraphRendererBase.highlightDirectConnections(selectedNode);
					}
				}
			}
			diagramDefaultKeyDown.call(this);
		};

		const diagramDefaultKeyUp = diagram.defaultTool.doKeyUp;
		diagram.defaultTool.doKeyUp = function () {
			const event = diagram.lastInput.event;
			if (event instanceof KeyboardEvent) {
				let selectedNode = getNodeUnderMouse(diagram);
				// Ensure it's a node (not a link or other part)
				if (!(selectedNode instanceof go.Node)) {
					return;
				}
				if (!isControlPressedKB(event)) {
					if (event.shiftKey && event.altKey) {
						GraphRendererBase.clearAllHighlighted(diagram);
						GraphRendererBase.recurseHighlightAllDescendants(selectedNode);
					} else if (event.shiftKey) {
						GraphRendererBase.clearAllHighlighted(diagram);
						GraphRendererBase.recurseHighlightAllAncestors(selectedNode);
					} else if (event.altKey) {
						GraphRendererBase.clearAllHighlighted(diagram);
						GraphRendererBase.highlightDirectConnections(selectedNode);
					} else {
						GraphRendererBase.clearAllHighlighted(diagram);
					}
				}
			}
			diagramDefaultKeyUp.call(this);
		};
	}

	public createDiagramModel(): go.GraphLinksModel {
		return new go.GraphLinksModel({
			nodeKeyProperty: 'key',
			linkKeyProperty: 'key',
		});
	}

	protected createBasicNodeTemplate(
		addMouseEvents: boolean,
		nodeWidth: number,
		onDoubleClick?: (event: go.InputEvent) => void,
	) {
		return new go.Node('Auto', {
			copyable: false,
			deletable: false,
			shadowOffset: new go.Point(0, 10),
			shadowBlur: 20,
			shadowColor: '#404142',
			selectionAdorned: false,
			...(addMouseEvents ? this.createMouseEvents(onDoubleClick) : {}),
			selectionChanged: (node: go.Part) => {
				if (node.diagram && node instanceof go.Node) {
					const diagram = node.diagram;
					diagram.model.commit((m) => {
						m.set(node, 'isShadowed', node.isSelected);
						node.linksConnected.each((link) => {
							// Check if the link has both to and from nodes
							if (link.toNode && link.fromNode) {
								// if the link connects two nodes both in the selection..
								if (
									diagram.selection.contains(link.toNode) &&
									diagram.selection.contains(link.fromNode)
								) {
									m.set(link, 'isSelected', true);
								} else {
									m.set(link, 'isSelected', false);
								}
							}
						});
					});
				}
			},
		})
			.bind(
				new go.Binding('visible', '', (data) => {
					return data.isVisible ?? true;
				}),
			)
			.bind(
				new go.Binding('isShadowed', 'isHighlighted', (h) =>
					h ? true : false,
				).ofObject(),
			)
			.add(
				new go.Shape('RoundedRectangle', {
					fill: 'white',
					strokeWidth: 2,
					parameter1: 6,
					margin: 0,
					spot1: go.Spot.TopLeft,
					spot2: go.Spot.BottomRight,
				})
					.bind(
						new go.Binding('stroke', 'isHighlighted', (h) => {
							return h ? '#558FA3' : 'grey';
						}).ofObject(),
					)
					.bind(
						new go.Binding('strokeWidth', 'isHighlighted', (h) => {
							return h ? 5 : 1;
						}).ofObject(),
					),
				new go.Panel('Vertical', {
					name: 'MainPanel',
					defaultStretch: go.Stretch.Horizontal,
					width: nodeWidth,
					margin: new go.Margin(0, 0, 16, 0),
				}),
			);
	}

	protected createNodeTemplateBase(
		addMouseEvents: boolean,
		mainHeaderPropertyBinding: string,
		createSubHeader: () => go.Panel,
		createSubHeader2: null | (() => go.Panel),
		createMainTable: () => go.GraphObject[],
		treeExpanderBuilder: string | null,
		nodeLinkBase: string,
		configurations: {
			nodeWidth: number;
			onDoubleClick?: (event: go.InputEvent) => void;
		} = {
			nodeWidth: 350,
		},
	) {
		let theNode = this.createBasicNodeTemplate(
			addMouseEvents,
			configurations.nodeWidth,
			configurations.onDoubleClick,
		)
			.bind('location', 'loc')
			.bind('movable', '', (data) => {
				return data.group !== 'Standalone';
			});
		let mainPanel = theNode.findObject('MainPanel') as go.Panel;
		let headerHeight = 30;
		mainPanel.add(
			new go.Panel('Spot', {
				height: headerHeight,
				shadowVisible: true,
				margin: new go.Margin(0, 0.5, 0, 0.5),
			}).add(
				new go.Shape('RoundedTopRectangle', {
					strokeWidth: 0,
					parameter1: 6,
					shadowVisible: true,
					height: headerHeight,
					width: configurations.nodeWidth,
				}).bind(new go.Binding('fill', '', (data) => data.currentColor)),
				new go.Shape('RoundedTopRectangle', {
					fill: 'rgba(0, 0, 0, .08)',
					parameter1: 6,
					width: configurations.nodeWidth,
					shadowVisible: true,
					strokeWidth: 0,
					height: headerHeight,
				}),
				go.GraphObject.make(
					'HyperlinkText',
					(node: go.Node) => {
						const legalEntityId = node.data.legalEntityId || node.data.entityId;

						if (legalEntityId) {
							return (
								nodeLinkBase +
								'/' +
								(node.data.legalEntityId || node.data.entityId) +
								'/information'
							);
						} else {
							return null;
						}
					},
					(node: go.Node) => node.data[mainHeaderPropertyBinding] || '-',
					{
						alignment: new go.Spot(0, 0.5, 8, 2),
						alignmentFocus: go.Spot.Left,
						wrap: go.Wrap.Fit,
						shadowVisible: true,
						width: configurations.nodeWidth,
						font: '16px bold Roboto, Helvetica, Arial, sans-serif',
						stroke: this._textColor,
					},
				),
			),
		);

		mainPanel.add(
			new go.Panel('Vertical', {
				margin: new go.Margin(0, 0.5, 0, 0.5),
				padding: 0,
				defaultSeparatorPadding: 0,
			})
				.bind(new go.Binding('background', '', (data) => data.currentColor))
				.add(createSubHeader()),
		);

		if (createSubHeader2 !== null) {
			mainPanel.add(
				new go.Shape('LineH', {
					stroke: 'grey',
					strokeWidth: 1,
					height: 0,
					alignment: go.Spot.Top,
					alignmentFocus: go.Spot.Top,
					stretch: go.Stretch.Horizontal,
				}),
				createSubHeader2(),
				new go.Shape('LineH', {
					stroke: 'grey',
					strokeWidth: 1,
					height: 1,
					stretch: go.Stretch.Horizontal,
				}),
			);
		}

		mainPanel.add(...createMainTable());
		if (treeExpanderBuilder) {
			theNode.add(
				go.GraphObject.make(treeExpanderBuilder, {
					alignment: go.Spot.Bottom,
					background: 'white',
				}),
			);
		}

		return theNode;
	}

	protected createTableRow(
		row: number,
		label: string,
		isVisible: boolean,
		bindingProperty: string,
		textFunction: (data: { [key: string]: any }) => string = (data: {
			[key: string]: any;
		}) => data[bindingProperty] || '-',
	): go.Panel {
		return go.GraphObject.make(
			go.Panel,
			'TableRow',
			{
				margin: new go.Margin(0, 0, 0, 0),
				row: row,
				name: `${bindingProperty}p`,
				visible: isVisible,
			},
			go.GraphObject.make(go.TextBlock, {
				text: label,
				column: 0,
				columnSpan: 2,
				margin: new go.Margin(0, 8, 0, 0),
				alignment: go.Spot.Left,
				font: 'bold 12px "Roboto","Helvetica","Arial",sans-serif',
				stroke: '#8d8c8d',
			}),
			go.GraphObject.make(
				go.TextBlock,
				{
					column: 2,
					font: '13px "Roboto","Helvetica","Arial",sans-serif',
					alignment: go.Spot.Left,
					width: 200,
					wrap: go.Wrap.DesiredSize,
					stroke: this._textColor,
				},
				new go.Binding('text', '', (data) => textFunction(data)),
			),
		);
	}
}
