/* eslint-disable mobx/missing-observer */
import { Divider } from '@mui/material';
import { E4AccountLink } from 'features/cash4/shared/components/T4SideDrawer/E4AccountLink';
import T4BlueHeader from 'features/cash4/shared/components/T4SideDrawer/T4BlueHeader';
import T4DetailBox from 'features/cash4/shared/components/T4SideDrawer/T4DetailBox';
import T4TopBar from 'features/cash4/shared/components/T4SideDrawer/T4TopBar';
import { FC, useMemo } from 'react';
import { formatDateSimpleUpperCase } from 'shared/utilities/dateUtilities';
import { formatCurrency } from 'utilities/currencyUtils';
import { TransactionListItem } from '../../models';
import { useTransactions } from '../../providers/useTransactions';
import {
	formattedExchangeRate,
	getTransactionCopyContentValue,
} from '../../utilities';
import { Action, Actions } from '../../../shared/components/T4ActionMenu';

export type TransactionDetailsProps = {
	transaction: TransactionListItem;
	disableLink?: boolean;
	transactionActions?: Action[];
};

const TransactionDetails: FC<TransactionDetailsProps> = ({
	transaction,
	disableLink,
	transactionActions,
}) => {
	const { configurations } = useTransactions();

	const formattedFxDate = useMemo(
		() =>
			transaction.number.reportingCurrencyEffectiveDate
				? formatDateSimpleUpperCase(
						transaction.number.reportingCurrencyEffectiveDate,
				  )
				: null,
		[transaction.number.reportingCurrencyEffectiveDate],
	);

	const reportingCurrencyCode = useMemo(
		() => configurations?.reportingCurrencyCode || 'USD',
		[configurations?.reportingCurrencyCode],
	);

	const actions = useMemo(() => {
		return (
			<Actions
				stonlyId={'cash4-transaction-details-context-menu'}
				actions={transactionActions!}
				id="transactionId-more-menu"
			/>
		);
	}, [transactionActions]);

	return (
		<>
			<T4TopBar
				title="Transaction Record"
				copyText={getTransactionCopyContentValue(
					transaction,
					reportingCurrencyCode,
				)}
				initialTooltipText="Copy Transaction Details"
				stonlyPrefix="transaction-details"
				disableLink={disableLink}
				customActionDropdown={actions}
			/>
			<T4BlueHeader
				bigNumber={transaction.number.value}
				currency={transaction.currency}
				subheadlines={[
					<E4AccountLink
						e4AccountId={transaction?.e4AccountId}
						e4AccountName={transaction?.e4AccountName}
						stonlyPrefix="transaction-details"
					/>,
					transaction.bankCode,
					formatDateSimpleUpperCase(transaction.date),
				]}
			/>
			<T4DetailBox
				details={[
					{
						type: 'bold',
						label: 'Account',
						value: transaction.e4AccountNumber,
						shouldDisplay: !!transaction.e4AccountNumber,
					},
					{
						type: 'bold',
						label: 'Cash4 Account',
						value: transaction.c4AccountNumber,
						shouldDisplay: !!transaction.c4AccountNumber,
					},
					{
						type: 'bold',
						label: 'Counterparty',
						value: transaction.bankName,
						shouldDisplay: !!transaction.bankName,
					},
					{
						type: 'bold',
						label: 'Transaction Date',
						value: formatDateSimpleUpperCase(transaction.date),
						shouldDisplay: !!transaction.date,
					},
					<Divider sx={{ mt: 2, mb: 2 }} />,
					{
						type: 'bold',
						label: 'Transaction Code',
						value: transaction.transactionCode,
						shouldDisplay: !!transaction.transactionCode,
					},
					{
						type: 'bold',
						label: 'Customer Reference',
						value: transaction.customerReference,
						shouldDisplay: !!transaction.customerReference,
					},
					{
						type: 'bold',
						label: 'Bank Reference',
						value: transaction.bankReference,
						shouldDisplay: !!transaction.bankReference,
					},
					{
						type: 'bold',
						label: 'Check Number',
						value: transaction.checkNumber,
						shouldDisplay: !!transaction.checkNumber,
					},
					{
						type: 'bold',
						label: 'Transaction Detail',
						value: transaction.detail,
						shouldDisplay: !!transaction.detail,
						lineBreak: true,
					},
					<Divider sx={{ mt: 2, mb: 2 }} />,
					{
						type: 'bold',
						label: 'Amount',
						value: `${formatCurrency(transaction.number.value, {
							currency: transaction.currency,
						})}  ${transaction.currency.toUpperCase()}`,
						shouldDisplay: !!transaction.number.value,
					},
					{
						type: 'bold',
						label: 'Reporting Ccy Amount',
						value: `${formatCurrency(transaction.number.reportingValue, {
							currency: reportingCurrencyCode,
						})} ${reportingCurrencyCode.toUpperCase()}`,
						shouldDisplay: !!transaction.number.reportingValue,
					},
					{
						type: 'bold',
						label: 'Foreign Exchange (FX) Rate',
						value: formattedExchangeRate(
							transaction.number.reportingCurrencyRate,
						),
						shouldDisplay: !!transaction.number.reportingCurrencyRate,
					},
					{
						type: 'bold',
						label: 'Foreign Exchange (FX) Date',
						value: formattedFxDate,
						shouldDisplay: !!transaction.number.reportingCurrencyEffectiveDate,
					},
				]}
			/>
		</>
	);
};

export default TransactionDetails;
