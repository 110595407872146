import { SigmaElement } from 'modules/clients/customer-api/src/sigmaEmbed';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseEmbedUrlProps = {
	isErrored: boolean;
	url: string | undefined;
	getEmbedUrl: () => Promise<void>;
};

export function useEmbedUrl(sigmaVisual: SigmaElement): UseEmbedUrlProps {
	const { enqueueSnackbar } = useSnackbar();
	const { customerApiClient } = useClients();

	const [_sigmaVisual] = useState(sigmaVisual);
	const [isErrored, setIsErrored] = useState(false);
	const [url, setUrl] = useState<string>();

	const [times, setTimes] = useState<number>(0);

	const getEmbedUrl = useCallback(async () => {
		setIsErrored(false);
		try {
			const response = await customerApiClient.sigmaEmbed.sigmaVizUrlv2({
				elementId: _sigmaVisual.elementId,
				workbookId: _sigmaVisual.workbookId,
				workbookUrlId: _sigmaVisual.workbookUrlId,
				embedId: _sigmaVisual.embedId,
			});
			if (response.data) {
				setUrl(response.data);
			} else {
				throw new Error();
			}
		} catch {
			setTimes((prev) => prev + 1);
		}
	}, [
		_sigmaVisual.elementId,
		_sigmaVisual.workbookId,
		customerApiClient.sigmaEmbed,
		_sigmaVisual.workbookUrlId,
	]);

	useEffect(() => {
		if (times > 0 && times < 3 && !url) {
			getEmbedUrl();
		} else if (times === 3 && !url) {
			setIsErrored(true);
			enqueueSnackbar(
				`Failed to get embed url for sigma visual: ${_sigmaVisual.name}.`,
				{
					variant: 'error',
				},
			);
		}
	}, [_sigmaVisual.name, enqueueSnackbar, getEmbedUrl, times]);

	return {
		isErrored: isErrored,
		url: url,
		getEmbedUrl: getEmbedUrl,
	};
}
