import {
	useT4Query,
	UseT4QueryProps,
} from 'features/cash4/_shared/_utilities/useT4Query';
import { VisualizationDataResponse } from 'modules/clients/customer-api/src/api/visualizations';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseVisualizationDataQueryProps =
	UseT4QueryProps<VisualizationDataResponse>;

export function useVisualizationDataQuery(): UseVisualizationDataQueryProps {
	const { customerApiClient } = useClients();

	const query = useCallback(async () => {
		const response = await customerApiClient.api.visualizations.data();

		return response.data?.data;
	}, [customerApiClient.api.visualizations]);
	const queryContext = useT4Query(query);

	return queryContext;
}
