import { Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import T4Drawer from 'features/cash4/shared/components/T4SideDrawer/T4DrawerShell';
import { ProjectionDrawer } from 'features/cash4/transactions/components/ProjectionDrawer/ProjectionDrawer';
import { observer } from 'mobx-react-lite';
import { ProjectedItem } from 'modules/clients/customer-api/src/api/cash4';
import { FC, useCallback, useMemo, useState } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
	USER_PREFERENCES_FIELD_OPTIONS,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { formatCurrency } from 'utilities/currencyUtils';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';
import {
	Actions,
	ActionsEnum,
} from 'features/cash4/shared/components/T4ActionMenu';
import { useUser } from 'shared/hooks/useUser';

export type ProjectedItemsGridProps = {
	stonlyId: string;
	tableKey: string;
	loading?: boolean;
	projectedItems: ProjectedItem[];
	selectedProjectedIds?: string[];
	setSelectedProjectedIds?: (selectedProjectedIds: string[]) => void;
};

export const ProjectedItemsGrid: FC<ProjectedItemsGridProps> = observer(
	({
		stonlyId,
		tableKey,
		loading = false,
		selectedProjectedIds,
		setSelectedProjectedIds,
		projectedItems,
	}) => {
		const { setSelectedProjectedItem, setProjectedItemDrawerOpen } =
			useReconciliationsContext();

		const useCheckboxSelection = useMemo(
			() => selectedProjectedIds !== undefined,
			[selectedProjectedIds],
		);
		const [projection, setProjection] = useState<ProjectedItem>();
		const [openProjectionDrawer, setOpenProjectionDrawer] = useState(false);

		const onRowSelectionModelChangeHandler = useMemo(() => {
			if (useCheckboxSelection && setSelectedProjectedIds) {
				return (projectedIds: GridRowSelectionModel) => {
					setSelectedProjectedIds(projectedIds.map((x) => x.toString()));
				};
			}
		}, [setSelectedProjectedIds, useCheckboxSelection]);

		const handleOpenProjectionDrawer = useCallback(() => {
			setOpenProjectionDrawer(true);
		}, []);

		const handleCloseProjectionDrawer = useCallback(() => {
			setOpenProjectionDrawer(false);
		}, []);

		const handleProjectedViewClick = useCallback(
			(projection: ProjectedItem) => {
				setProjection(projection);
				handleOpenProjectionDrawer();
			},
			[handleOpenProjectionDrawer],
		);

		const handleProjectedEditClick = useCallback(
			(projection: ProjectedItem) => {
				setSelectedProjectedItem(projection);
				setProjectedItemDrawerOpen(true);
			},
			[setProjectedItemDrawerOpen, setSelectedProjectedItem],
		);

		const user = useUser();

		const columns = useMemo<GridColDef<ProjectedItem>[]>(() => {
			const columns: GridColDef<ProjectedItem>[] = [
				{
					field: 'label',
					headerName: 'Label',
				},
				{
					field: '_primaryParty_name',
					headerName: 'Entity',
					valueGetter: (params) => params.row.primaryParty.object?.name,
				},
				{
					field: '_primaryParty__account_name',
					headerName: 'Account',
					valueGetter: (params) => params.row.primaryParty.account?.name,
				},
				{
					field: '_primaryParty__account_number',
					headerName: 'Account number',
					valueGetter: (params) => params.row.primaryParty.account?.number,
				},
				{
					field: 'flowDirection',
					headerName: 'Fund direction',
				},
				{
					...getCurrencyColumnDefinition(),
					field: 'amount',
					headerName: 'Amount',
					renderCell: (params) =>
						formatCurrency(
							(params.row.flowDirection === 'Inflow' ? 1 : -1) *
								params.row.amount,
							{
								currency: params.row.currencyCode,
							},
						),
				},
				{
					field: 'currencyCode',
					headerName: 'Currency',
				},
				{
					...getDateColumnDefinition(),
					field: 'date',
					headerName: 'Expected value date',
				},
				{
					field: '_secondaryParty_type',
					headerName: 'Type',
					valueGetter: (params) => params.row.secondaryParty.type,
				},
				{
					field: '_secondaryParty_name',
					headerName: 'Secondary entity',
					valueGetter: (params) => params.row.secondaryParty.object?.name,
				},
				{
					field: '_secondaryParty__account_name',
					headerName: 'Secondary account',
					valueGetter: (params) => params.row.secondaryParty.account?.name,
				},
				{
					field: '_secondaryParty__account_number',
					headerName: 'Secondary account number',
					valueGetter: (params) => params.row.secondaryParty.account?.number,
				},
				{
					field: '_cfc',
					headerName: 'CFC',
					description: 'Cash flow class',
					width: DataGridColumnWidths.threeChar,
					valueGetter: (params) => params.row?.categorization?.class?.code,
					renderCell: (params) => {
						return (
							<Tooltip title={params.row.categorization?.class?.name}>
								<Typography variant="body2" noWrap>
									{params.value}
								</Typography>
							</Tooltip>
						);
					},
				},
				{
					field: '_cft',
					headerName: 'CFT',
					description: 'Cash flow type',
					width: DataGridColumnWidths.threeChar,
					valueGetter: (params) => params.row?.categorization?.type?.code,
					renderCell: (params) => {
						return (
							<Tooltip title={params.row.categorization?.type?.name}>
								<Typography variant="body2" noWrap>
									{params.value}
								</Typography>
							</Tooltip>
						);
					},
				},
				{
					field: '_cfst',
					headerName: 'CFST',
					description: 'Cash flow subtype',
					width: DataGridColumnWidths.fourChar,
					valueGetter: (params) => params.row?.categorization?.subtype?.code,
					renderCell: (params) => {
						return (
							<Tooltip title={params.row.categorization?.subtype?.name}>
								<Typography variant="body2" noWrap>
									{params.value}
								</Typography>
							</Tooltip>
						);
					},
				},
				{
					field: '_glCode',
					headerName: 'GL code',
					valueGetter: (params) => params.row?.categorization?.glCode?.code,
				},
				{
					field: 'reconciliationStatus',
					headerName: 'Reconciliation status',
					valueGetter: (params) =>
						params.value === 'PartiallyReconciled'
							? 'Partially reconciled'
							: params.value === 'Posted'
							? 'No reconciliation'
							: params.value,
				},
			];

			if (!useCheckboxSelection) {
				columns.unshift({
					...getOptionsMenuColDef((params) => {
						return (
							<Actions
								stonlyId="c4-create-reconciliation-projected-item"
								actions={[
									{
										action: ActionsEnum.view,
										callback: () => handleProjectedViewClick(params.row),
									},
									...(user.cash4.isAuthor
										? [
												{
													action: ActionsEnum.edit,
													callback: () => handleProjectedEditClick(params.row),
												},
										  ]
										: []),
								]}
								id="projectedTransactionId-more-menu"
							/>
						);
					}),
				});
			}

			return columns;
		}, [
			handleProjectedViewClick,
			handleProjectedEditClick,
			useCheckboxSelection,
		]);

		return (
			<>
				<UserPreferencesDataGrid
					stonlyId={stonlyId}
					tableKey={tableKey}
					loading={loading}
					rows={projectedItems}
					hideFooter={false}
					showToolbar={true}
					showCustomViewButton
					pagination
					autoPageSize
					checkboxSelection={useCheckboxSelection}
					disableRowSelectionOnClick={useCheckboxSelection}
					rowSelectionModel={selectedProjectedIds}
					onRowSelectionModelChange={onRowSelectionModelChangeHandler}
					columns={columns}
					columnVisibilityModel={{
						reconciliationStatus: false,
					}}
					initialState={{
						sorting: {
							sortModel: [{ field: 'date', sort: 'asc' }],
						},
					}}
					pinnedColumns={{
						left: [USER_PREFERENCES_FIELD_OPTIONS],
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							hideExport: true,
							quickFilterProps: {
								variant: 'outlined',
								size: 'small',
							},
						},
					}}
				/>
				<T4Drawer
					open={openProjectionDrawer}
					onClose={handleCloseProjectionDrawer}
					stonlyPrefix="projected-transaction-details"
				>
					{projection && (
						<ProjectionDrawer
							projection={projection}
							loading={false}
							disableLink
						/>
					)}
				</T4Drawer>
			</>
		);
	},
);
