import {
	Account,
	Relationship,
} from 'modules/clients/customer-api/src/api/visualizations';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import { useVisualizationDataQuery } from './useVisualizationDataQuery';

export type VisualizationAccount = {
	id: string;
	parentId: string | undefined;
	legalEntityId: string | undefined;
	erpCode: string | undefined;
	accountCode: string;
	accountStatus: string | undefined;
	accountType: string | undefined;
	accountCurrencyCode: string | undefined;
	naturalAccountNumber: string | undefined;
	entityName: string | undefined;
	entityRegion: string | undefined;
	entityErpCode: string | undefined;
	counterparty: string | undefined;
	counterpartyBranch: string | undefined;
	purpose: string | undefined;

	key: string;
	group: string | undefined;
	isSubaccount: boolean;
	isForeignAccount: boolean;
	currentColor: string | undefined;
	isVisible: boolean;
	isAccountTypeVisible: boolean;
	isEntityRegionVisible: boolean;
	isAccountPurposeVisible: boolean;
	isAccountStatusVisible: boolean;
	entityViewFundingStructure?: 'left' | 'right';
};

export type VisualizationLink = {
	key: string;
	from: string;
	to: string;
	isSubaccount?: boolean;
	type?: string;
	movement?: string;
	isLeftSide?: boolean;
};

type LegalEntityAccountGroupFundingStructure = [
	string,
	{
		id: string;
		funded: string[];
		funding: string[];
	}[],
];

type AccountFundingStructure = [
	string,
	{
		funded: string[];
		funding: string[];
	},
];

function fromToFilter(asOfDate: Moment) {
	return (x: Relationship) =>
		(x.from === undefined && x.to === undefined) ||
		(x.from === undefined && asOfDate <= moment(x.to)) ||
		(asOfDate >= moment(x.from) && x.to === undefined) ||
		(asOfDate >= moment(x.from) && asOfDate <= moment(x.to));
}

export type UseAccountVisualizationsProps = {
	accountView: {
		nodes: any[];
		links: VisualizationLink[];
	};
	entityAccountView: {
		nodes: any[];
		links: any[];
	};
};

export function useAccountVisualizations(opt: {
	isFlagVisible: boolean;
	isSubaccountsVisible: boolean;
	isClosedAccountsVisible: boolean;
	isAccountTypeVisible: boolean;
	isEntityRegionVisible: boolean;
	isAccountPurposeVisible: boolean;
	isAccountStatusVisible: boolean;
	getNodeColor: (data: {
		purpose: string | undefined;
		accountStatus: string | undefined;
		entityRegion: string | undefined;
	}) => string;
}): UseAccountVisualizationsProps {
	const { data } = useVisualizationDataQuery();

	const visualizationData = useMemo<UseAccountVisualizationsProps>(() => {
		const today = moment().startOf('day');

		const filteredAccounts: Account[] =
			data?.accounts
				?.filter((x) => opt.isClosedAccountsVisible || x.status !== 'Closed')
				.map((x) => {
					return {
						...x,
						subaccounts: x.subaccounts?.filter(
							(x) =>
								opt.isSubaccountsVisible &&
								(opt.isClosedAccountsVisible || x.status !== 'Closed'),
						),
					};
				}) ?? [];

		//#region Helpers

		const getLegalEntity = (id: string) =>
			data?.legalEntities.find(
				(x) =>
					x.accounts
						?.filter(fromToFilter(today))
						.some((x) => x.objectId === id),
			);

		const getCounterparty = (id?: string) =>
			data?.counterparties?.find((x) => x.id === id);

		const isAccountVisible = (account: VisualizationAccount) => {
			let isVisible = true;

			if (account.isSubaccount && !opt.isSubaccountsVisible) {
				isVisible = false;
			}

			if (account.accountStatus === 'Closed' && !opt.isClosedAccountsVisible) {
				isVisible = false;
			}

			return isVisible;
		};

		const accountFundingStructures =
			filteredAccounts
				?.map<AccountFundingStructure>((account) => {
					const funding =
						account.fundedAccounts
							?.filter(fromToFilter(today))
							.map((x) => x.objectId) ?? [];
					const funded =
						filteredAccounts
							?.filter(
								(x) =>
									x.fundedAccounts
										?.filter(fromToFilter(today))
										?.some((y) => y.objectId === account.id),
							)
							.map((x) => x.id) ?? [];

					return [
						account.id,
						{
							funded: funded,
							funding: funding,
						},
					];
				})
				?.sort(
					(a, b) =>
						b[1].funded.length +
						b[1].funding.length -
						(a[1].funded.length + a[1].funding.length),
				) ?? [];
		let accountFundingStructureGrouping: string[][] = [];
		accountFundingStructures.forEach((fundingStructure) => {
			let grouping = accountFundingStructureGrouping.find((x) =>
				x.includes(fundingStructure[0]),
			);
			if (grouping === undefined) {
				grouping = [fundingStructure[0]];
				accountFundingStructureGrouping.push(grouping);
			}

			grouping.push(
				...fundingStructure[1].funded.filter((x) => !grouping?.includes(x)),
			);
			grouping.push(
				...fundingStructure[1].funding.filter((x) => !grouping?.includes(x)),
			);
		});
		accountFundingStructureGrouping = accountFundingStructureGrouping.sort(
			(a, b) => b.length - a.length,
		);

		//#endregion

		//#region Entity View

		let legalEntityGroupAccountFundingStructures: LegalEntityAccountGroupFundingStructure[] =
			data?.legalEntities?.flatMap((legalEntity) => {
				const ownedAccounts =
					legalEntity.accounts?.filter(fromToFilter(today)) ?? [];
				const ownedAccountIds = ownedAccounts.map((x) => x.objectId);

				const accountGroupings: {
					id: string;
					funded: string[];
					funding: string[];
				}[][] = [];
				ownedAccounts.forEach((ownedAccount) => {
					let account = filteredAccounts.find(
						(x) => x.id === ownedAccount.objectId,
					);
					if (account !== undefined) {
						let grouping = accountGroupings.find(
							(x) => !!x.find((x) => x.id === account?.id),
						);
						if (grouping === undefined) {
							grouping = [];
							accountGroupings.push(grouping);
						}

						const getFundingStructure = (accountId: string) => {
							const accountFundingStructure = accountFundingStructures.find(
								(x) => x[0] === account?.id,
							);
							const fundedBy =
								accountFundingStructure?.[1].funded.filter((x) =>
									ownedAccountIds.includes(x),
								) ?? [];
							const funding =
								accountFundingStructure?.[1].funding.filter((x) =>
									ownedAccountIds.includes(x),
								) ?? [];

							return {
								id: accountId,
								funded: fundedBy,
								funding: funding,
							};
						};

						if (grouping !== undefined) {
							const fundingStructure = getFundingStructure(account.id);

							grouping.push(
								fundingStructure,
								...(account?.fundedAccounts
									?.filter(fromToFilter(today))
									?.filter(
										(x) =>
											ownedAccountIds.includes(x.objectId) &&
											(grouping === undefined ||
												!grouping.map((x) => x.id).includes(x.objectId)),
									)
									?.map((x) => getFundingStructure(x.objectId)) ?? []),
							);
						}
					}
				});

				return accountGroupings
					.sort((a, b) => b.length - a.length)
					.map<LegalEntityAccountGroupFundingStructure>((grouping) => {
						return [legalEntity.id, grouping];
					});
			}) ?? [];
		legalEntityGroupAccountFundingStructures.forEach((x) => {
			if (x[1].length > 0) {
				legalEntityGroupAccountFundingStructures
					.filter((y) => y[0] === x[0] && x !== y)
					.forEach((y) => {
						if (
							x[1]
								.map((x) => x.id)
								.some((z) => y[1].map((x) => x.id).includes(z))
						) {
							[...y[1]].forEach((z) => {
								if (!x[1].find((y) => y.id === z.id)) {
									x[1].push(z);
								}
								y[1].splice(y[1].length - 1, 1);
							});
						}
					});
			}
		});
		legalEntityGroupAccountFundingStructures =
			legalEntityGroupAccountFundingStructures.filter((x) => x[1].length > 0);

		const winningAccounts: [
			string,
			string,
			{ id: string; funded: string[]; funding: string[] }[],
		][] = [];
		const legalEntityNodeLinks: VisualizationLink[] =
			data?.legalEntities?.flatMap<VisualizationLink>((legalEntity) => {
				return legalEntityGroupAccountFundingStructures
					.filter((x) => x[0] === legalEntity.id)
					.flatMap<VisualizationLink>((accountGroupings) => {
						const [, accountGroup] = accountGroupings;
						const accounts = filteredAccounts.filter((x) =>
							accountGroup.map((x) => x.id).includes(x.id),
						);
						const ownsConcentrationAccount = accounts?.some(
							(x) => x.purpose === 'Concentration',
						);

						const winningAccount = accountGroup.find((structure) => {
							const account = accounts?.find((x) => x.id === structure.id);

							if (
								!ownsConcentrationAccount ||
								account?.purpose === 'Concentration'
							) {
								return true;
							}

							return false;
						});

						if (winningAccount) {
							winningAccounts.push([
								legalEntity.id,
								winningAccount.id,
								accountGroup,
							]);
						}

						return [
							{
								key: `${legalEntity.id}-${winningAccount?.id ?? ''}`,
								from: legalEntity.id,
								to: winningAccount?.id ?? '',
								isSubaccount: false,
								type: 'Entity',
							},
							...(accounts
								?.filter((x) => winningAccount?.funded.includes(x.id))
								.flatMap((account) => [
									...(account.fundedAccounts
										?.filter((x) => x.objectId === winningAccount?.id)
										.map((x) => ({
											key: `${winningAccount?.id}-${x.objectId}`,
											from: x.objectId,
											to: account.id,
											isSubaccount: false,
											type: x.fundingDirection,
											movement: x.cashFlowMovement,
											isLeftSide: true,
										})) ?? []),
									...(account.subaccounts?.map((x) => ({
										key: `${account?.id ?? ''}-${x.id}`,
										from: account?.id ?? '',
										to: x.id,
										isSubaccount: true,
										type: 'Subaccount',
									})) ?? []),
								]) ?? []),
							...(accounts
								?.filter((x) => winningAccount?.id === x.id)
								.flatMap<VisualizationLink>((account) => {
									return [
										...(account.fundedAccounts
											?.filter((x) =>
												accounts.map((x) => x.id).includes(x.objectId),
											)
											?.map((x) => ({
												key: `${account?.id}-${x.objectId}`,
												from: account?.id ?? '',
												to: x.objectId,
												isSubaccount: false,
												type: x.fundingDirection,
												movement: x.cashFlowMovement,
												isLeftSide: false,
											})) ?? []),
										...(account.subaccounts?.map((x) => ({
											key: `${account?.id ?? ''}-${x.id}`,
											from: account?.id ?? '',
											to: x.id,
											isSubaccount: true,
											type: 'Subaccount',
										})) ?? []),
									];
								}) ?? []),
						];
					});
			}) ?? [];

		const legalEntityNodes =
			data?.legalEntities
				?.map((x) => {
					const accounts =
						x.accounts
							?.filter(fromToFilter(today))
							.map((x) => filteredAccounts.find((y) => y.id === x.objectId))
							.filter((x) => x !== undefined) ?? [];
					const accountsCount =
						accounts.length +
						accounts.flatMap((x) => x?.subaccounts ?? []).length;

					return {
						key: x.id,
						category: 'entityOrg',
						entityId: x.id,
						entityCountry: x.country,
						entityErpCode: x.erpCode,
						entityName: x.anglicizedLegalName,
						isFlagVisible: opt.isFlagVisible,
						countChildren: accountsCount,
					};
				})
				?.filter((x) => x.countChildren > 0) ?? [];

		//#endregion

		//#region Accounts

		const accountsNodes: VisualizationAccount[] =
			filteredAccounts
				?.flatMap(
					({
						id: accountId,
						code,
						status,
						type,
						currencyCode,
						number,
						counterpartyId,
						fundedAccounts,
						subaccounts,
						...rest
					}) => {
						const counterparty = getCounterparty(counterpartyId);
						const counterpartyUltimateParent =
							counterparty?.ultimateParent?.substring(0, 4);
						const counterpartyBranch = counterparty?.anglicizedLegalName;
						const legalEntity = getLegalEntity(accountId);
						const erpCode = legalEntity?.erpCode;

						const isForeignAccount = (accountCountry?: string) => {
							if (accountCountry && legalEntity?.country) {
								return accountCountry !== legalEntity.country;
							}

							return false;
						};

						const getEntityViewFundingStructureSide = (id: string) => {
							if (legalEntityNodeLinks.find((x) => x.to === id)?.isLeftSide) {
								return 'left';
							} else {
								return 'right';
							}
						};

						const accountData: VisualizationAccount = {
							key: accountId,
							isSubaccount: false,
							isForeignAccount: isForeignAccount(counterparty?.country),
							group: undefined,
							erpCode: erpCode,
							purpose: rest.purpose,

							id: accountId,
							parentId: undefined,
							legalEntityId: legalEntity?.id,
							accountCode: code,
							accountStatus: status,
							accountType: type,
							accountCurrencyCode: currencyCode,
							naturalAccountNumber: number,

							entityName: legalEntity?.anglicizedLegalName,
							entityRegion: legalEntity?.entityRegion,
							entityErpCode: legalEntity?.erpCode,

							counterparty: counterpartyUltimateParent,
							counterpartyBranch: counterpartyBranch,

							currentColor: undefined,
							isVisible: true,
							isAccountTypeVisible: true,
							isEntityRegionVisible: true,
							isAccountPurposeVisible: true,
							isAccountStatusVisible: true,
							entityViewFundingStructure:
								getEntityViewFundingStructureSide(accountId),

							...rest,
						};

						const subaccountsData: VisualizationAccount[] =
							subaccounts?.map(
								({
									id: subaccountId,
									code,
									status,
									type,
									number,
									currencyCode,
									...rest
								}) => ({
									key: subaccountId,
									isSubaccount: true,
									isForeignAccount: isForeignAccount(counterparty?.country),
									group: undefined,
									erpCode: erpCode,
									purpose: rest.purpose,

									id: subaccountId,
									parentId: accountId,
									legalEntityId: legalEntity?.id,
									accountCode: code,
									accountStatus: status,
									accountType: type,
									accountCurrencyCode: currencyCode,
									naturalAccountNumber: number,

									entityName: legalEntity?.anglicizedLegalName,
									entityRegion: legalEntity?.entityRegion,
									entityErpCode: legalEntity?.erpCode,

									counterparty: counterpartyUltimateParent,
									counterpartyBranch: counterpartyBranch,

									currentColor: undefined,
									isVisible: true,
									isAccountTypeVisible: true,
									isEntityRegionVisible: true,
									isAccountPurposeVisible: true,
									isAccountStatusVisible: true,

									...rest,
								}),
							) ?? [];

						return [accountData, ...subaccountsData];
					},
				)
				.map((x) => ({
					...x,
					currentColor: opt.getNodeColor({
						accountStatus: x.accountStatus,
						purpose: x.purpose,
						entityRegion: x.entityRegion,
					}),
					isVisible: isAccountVisible(x),
					isAccountTypeVisible: opt.isAccountTypeVisible,
					isEntityRegionVisible: opt.isEntityRegionVisible,
					isAccountPurposeVisible: opt.isAccountPurposeVisible,
					isAccountStatusVisible: opt.isAccountStatusVisible,
				}))
				.sort((a, b) => {
					const aIndex = accountFundingStructureGrouping.findIndex((x) =>
						x.includes(a.id),
					);
					const bIndex = accountFundingStructureGrouping.findIndex((x) =>
						x.includes(b.id),
					);

					return aIndex - bIndex;
				}) ?? [];

		//#endregion

		//#region Account View Links

		const accountLinks: VisualizationLink[] =
			filteredAccounts.flatMap(({ id, fundedAccounts, subaccounts }) => {
				const fundingLinks: VisualizationLink[] =
					fundedAccounts
						?.filter(fromToFilter(today))
						?.filter((x) => filteredAccounts.some((y) => y.id === x.objectId))
						.map(({ objectId, fundingDirection, cashFlowMovement }) => ({
							key: `${id}-${objectId}`,
							from: id,
							to: objectId,
							type: fundingDirection,
							movement: cashFlowMovement,
						})) ?? [];

				const subaccountLinks: VisualizationLink[] =
					subaccounts?.map(({ id: subaccountId }) => ({
						key: `${id}-${subaccountId}`,
						from: id,
						to: subaccountId,
						type: 'Subaccount',
						isSubaccount: true,
					})) ?? [];

				return fundingLinks.concat(subaccountLinks);
			}) ?? [];

		//#endregion

		//#region Account View Legend Nodes

		const legalEntities =
			data?.legalEntities
				?.filter((x) => (x.accounts?.length ?? 0) > 0)
				.sort() ?? [];
		const entityRegions =
			legalEntities
				?.filter((x) => x.entityRegion !== undefined)
				.map((x) => x.entityRegion) ?? [];
		const distinctEntityRegions = [...new Set(entityRegions)].filter(Boolean);
		const accountTypes = filteredAccounts
			?.filter((x) => x.type !== undefined)
			.map((x) => x.type)
			.sort();
		const distinctAccountTypes = [...new Set(accountTypes)].filter(Boolean);
		const accountStatuses = filteredAccounts
			?.filter((x) => x.status !== undefined)
			.map((x) => x.status);
		const distinctAccountStatuses = [...new Set(accountStatuses)].filter(
			Boolean,
		);
		const accountPurposes = filteredAccounts
			?.filter((x) => x.purpose !== undefined)
			.map((x) => x.purpose);
		const distinctAccountPurposes = [...new Set(accountPurposes)].filter(
			Boolean,
		);

		const legendNodes = [
			{
				key: 'Legend',
				isGroup: true,
				category: 'Legend',
			},
			{
				key: 'LegendNode',
				group: 'Legend',
				category: 'LegendNode',
				accountTypes: distinctAccountTypes,
				entityRegions: distinctEntityRegions,
				hasForeignAccount: accountsNodes.some((x) => x.isForeignAccount),
				hasManualLink:
					filteredAccounts.some(
						(x) =>
							x.fundedAccounts?.some((x) => x.cashFlowMovement === 'Manual'),
					) ?? false,
				hasAutomaticLink:
					filteredAccounts.some(
						(x) =>
							x.fundedAccounts?.some((x) => x.cashFlowMovement === 'Automatic'),
					) ?? false,
				hasOneWayLink:
					filteredAccounts.some(
						(x) =>
							x.fundedAccounts?.some((x) => x.fundingDirection === 'One-Way'),
					) ?? false,
				hasTwoWayLink:
					filteredAccounts.some(
						(x) =>
							x.fundedAccounts?.some((x) => x.fundingDirection === 'Two-Way'),
					) ?? false,
				hasSubaccount: accountsNodes.some((x) => x.isSubaccount),
				hideConnectors: accountLinks.length === 0, //this is done in the account view.
				accountStatuses: distinctAccountStatuses,
				accountPurposes: distinctAccountPurposes,
				isSubaccountVisible: opt.isSubaccountsVisible,
				isAccountTypeVisible: opt.isAccountTypeVisible,
				isEntityRegionVisible: opt.isEntityRegionVisible,
				isAccountStatusVisible: opt.isAccountStatusVisible,
				isAccountPurposeVisible: opt.isAccountPurposeVisible,
			},
		];

		//#endergion

		//#region Entity Account Links

		//#endregion

		const accountViewNodes: any[] = [];
		accountViewNodes.push(
			...accountsNodes.map((x) => ({
				...x,
				group: accountLinks.some((y) => y.from === x.key || y.to === x.key)
					? undefined
					: 'Standalone',
			})),
		);
		if (accountViewNodes.some((x) => x.group === 'Standalone')) {
			accountViewNodes.push({
				key: 'Standalone',
				text: 'Standalone Accounts',
				isGroup: true,
			});
		}
		accountViewNodes.push(...legendNodes);

		const entityAccountViewNodes: any[] = [];
		entityAccountViewNodes.push(...legalEntityNodes);
		entityAccountViewNodes.push(
			...accountsNodes.map((x) => ({
				...x,
				group: legalEntityNodeLinks.some(
					(y) => y.to === x.key || y.from === x.key,
				)
					? undefined
					: 'Standalone',
			})),
		);
		if (entityAccountViewNodes.some((x) => x.group === 'Standalone')) {
			entityAccountViewNodes.push({
				key: 'Standalone',
				text: 'Standalone Accounts',
				isGroup: true,
			});
		}
		entityAccountViewNodes.push(...legendNodes);

		return {
			accountView: {
				nodes: accountViewNodes,
				links: accountLinks,
			},
			entityAccountView: {
				nodes: entityAccountViewNodes,
				links: legalEntityNodeLinks,
			},
		};
	}, [data?.accounts, data?.legalEntities, data?.counterparties, opt]);

	return visualizationData;
}
